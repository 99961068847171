import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useChatContext } from "../context/ChatContext";

export default function LayoutPublic() {
  const chat = useChatContext();
  useEffect(() => {
    if (chat?.current) {
      chat.current.classList.add("chat-widget-hidden");
    }
  }, []);
  return (
    <main className="relative flex flex-grow flex-col bg-[#FFFEFB]">
      <Outlet />

      <div className="py-10 text-[18px] text-dark-blue">
        <div className="mb-2 flex w-full flex-row items-center justify-center gap-5">
          <a
            className="font-interlight hover:underline"
            href="https://fenixbegravning.se/pdf/Fenix-Allmanna-villkor.pdf"
          >
            Allmänna villkor
          </a>
          <a
            className="font-interlight hover:underline"
            href="https://fenixbegravning.se/pdf/Fenix-Integritetspolicy.pdf"
          >
            Integritetspolicy
          </a>
        </div>
        <div className="text-center font-interlight">
          © Fenix Family AB 2023
        </div>
      </div>
    </main>
  );
}
