import React, { useEffect, useRef, useState } from "react";

import { useContract } from "../../context/ContractContext";
import { Contract } from "../../services/contracts";
import { getRestartIcon } from "../../services/icons";
import { dataDeepCopy } from "../../services/utils";
import { ContractPrevState } from "../../types/contract";
import {
  AnswerData,
  AnswersLog,
  AnswersLogItem,
  ContractSet,
  CurrentQuestion,
  HierarchyItem,
  QuestionItem,
  QuestionTree,
  VariablesList,
} from "../../types/entities";
import Button from "../Button";
import Question from "./Question";
import QuestionEditWrapper from "./QuestionEditWrapper";
import QuestionSummary from "./QuestionSummary";
import UserInfo from "./UserInfo";

type Qeul = (
  question: HierarchyItem,
  tree: QuestionTree,
  depth: number
) => Qeul | boolean;

type Props = {
  contract: Contract;
  data: ContractSet;
  submitAnswers: () => void;
};

export default function QuestionList({ contract, data, submitAnswers }: Props) {
  // const [hierarchy, setHierarchy] = useState<HierarchyItem[]>();
  // const [questions, setQuestions] = useState<QuestionItem[]>();
  const [variables, setVariables] = useState<VariablesList>({});
  const [editQuestionId, setEditQuestionId] = useState<string>();

  const { contractData, dispatch } = useContract();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (data === undefined) {
      return;
    }
    // setQuestions(data.questions);

    const hierarchyFlat = [] as Array<HierarchyItem>;
    for (const [k, v] of Object.entries(data.hierarchy)) {
      for (const [i, obj] of Object.entries(v)) {
        hierarchyFlat.push(obj);
      }
    }
    // setHierarchy(hierarchyFlat);
    //
    // if (hierarchy === undefined) {
    //   return;
    // }

    // get root question list
    const rootQuestionList = hierarchyFlat.filter((el) => {
      return el.depth === contractData.currentDepth;
    });

    const baseQuestionTree: QuestionTree = {};
    data.hierarchy.map((el, ind) => {
      baseQuestionTree[ind] = { index: 0, list: [] };
    });
    baseQuestionTree[0].list = rootQuestionList;
    dispatch({
      type: "set-data",
      payload: {
        questionTree: baseQuestionTree,
        questions: data.questions,
        hierarchy: hierarchyFlat,
      },
    });
  }, [data]);

  useEffect(() => {
    // set initial first question
    if (contractData.questionTree) {
      formatAndSetCurrentQuestion(contractData.questionTree[0].list[0]);
    }
  }, [contractData.hierarchy]);

  useEffect(() => {
    if (contractData.restorePrevState) {
      submitQuestionData(contractData.restorePrevState);
      dispatch({ type: "remove-prev-state" });
    }
  }, [contractData.restorePrevState]);

  const formatAndSetCurrentQuestion = (
    hierarchyQuestion: HierarchyItem
  ): CurrentQuestion | false => {
    const question = getQuestionById(hierarchyQuestion.id);
    if (!question) return false;

    const questionFormatted = {
      q: question,
      h: hierarchyQuestion,
    };

    dispatch({ type: "current-question", payload: questionFormatted });
    return questionFormatted;
  };

  const getQuestionById = (id: string): QuestionItem | false => {
    if (!contractData.questions) return false;

    const res = contractData.questions.filter((element) => {
      return element._id === id;
    });
    return res.length > 0 ? res[0] : false;
  };

  const ifQuestionExistsInUpperLevels: Qeul = (question, tree, depth) => {
    const upDepth = depth - 1;
    if (upDepth < 0) {
      return false;
    }

    if (!tree[upDepth] || !tree[upDepth].list) {
      return false;
    }

    const res = tree[upDepth].list.filter((el) => {
      return el.id === question.id;
    });

    if (res.length > 0) {
      return true;
    }

    return ifQuestionExistsInUpperLevels(question, tree, depth - 1);
  };

  const submitQuestionData = (answerData: AnswerData[], cData) => {
    const cd = cData ? cData : contractData;
    if (!cd.currentQuestion || !cd.questionTree) return false;

    const log = dataDeepCopy(cd.answersLog);
    const structuredAnswers: AnswersLogItem = {};
    answerData.map((el) => {
      structuredAnswers[el.id] = { value: el.value, selected: true };
    });
    log[cd.currentQuestion.q._id] = {
      fields: structuredAnswers,
      // prevState: {
      //   currentQuestion: cd.currentQuestion,
      //   questionTree: cd.questionTree,
      //   currentDepth: cd.currentDepth,
      //   answersLog: cd.answersLog,
      // },
    };

    dispatch({
      type: "answers-log",
      payload: log,
    });

    let tree = dataDeepCopy(cd.questionTree);
    let depth = cd.currentDepth;
    let zeroIndex = false;

    // check if answer has nested questions
    const answerId = answerData.length === 1 ? answerData[0].id : false;
    const answerFids = cd.currentQuestion.h.fids;
    if (
      answerId &&
      answerFids &&
      answerFids[answerId] &&
      answerFids[answerId].length > 0
    ) {
      const nestedIds = answerFids[answerId];
      depth += 1;

      tree = insertQuestionsIntoTree(nestedIds, tree, depth);
      zeroIndex = true;
    }

    getNextQuestion(tree, depth, zeroIndex);
  };

  const updateQuestionData = (answerData: AnswerData[], questionId: string) => {
    const prevFields = contractData.answersLog[questionId]?.fields;
    // const prevState = contractData.answersLog[questionId].prevState;
    const prevState = false;
    if (prevState) {
      const prevAnswerType = prevState.currentQuestion.q.type;
      if (prevAnswerType === "singleAnswer") {
        let prevAnswerId = "";
        for (const [key, value] of Object.entries(prevFields)) {
          prevAnswerId = key;
        }

        const prevAnswerDep = prevState.currentQuestion.h.fids[prevAnswerId];
        const newAnswerDep = prevState.currentQuestion.h.fids[answerData[0].id];

        if (JSON.stringify(prevAnswerDep) !== JSON.stringify(newAnswerDep)) {
          dispatch({
            type: "set-prev-state",
            payload: {
              currentQuestion: prevState.currentQuestion,
              questionTree: prevState.questionTree,
              currentDepth: prevState.currentDepth,
              answersLog: prevState.answersLog,
              restorePrevState: answerData,
            },
          });
          return;
        }
      }
    }

    const log = dataDeepCopy(contractData.answersLog);
    const structuredAnswers: AnswersLogItem = {};
    answerData.map((el) => {
      structuredAnswers[el.id] = { value: el.value, selected: true };
    });
    log[questionId] = {
      fields: structuredAnswers,
      // prevState: { ...prevState },
    };
    dispatch({
      type: "answers-log",
      payload: log,
    });
  };

  const getNextQuestion = (
    tree: QuestionTree,
    depth: number,
    zeroIndex = false
  ) => {
    // proceed with current depth
    const nextIndex = zeroIndex ? tree[depth].index : tree[depth].index + 1;
    if (tree[depth].list[nextIndex]) {
      const hierarchyQuestion = tree[depth].list[nextIndex];
      tree[depth].index = nextIndex;

      dispatch({
        type: "question-tree",
        payload: tree,
      });

      const questionExist = ifQuestionExistsInUpperLevels(
        hierarchyQuestion,
        tree,
        depth
      );

      // check if question was answered
      const hasAnswerLogged = contractData.answersLog[hierarchyQuestion.id];

      if (questionExist || hasAnswerLogged) {
        let zeroIndex = false;

        if (hasAnswerLogged) {
          const answeredId = Object.keys(
            contractData.answersLog[hierarchyQuestion.id].fields
          )[0];

          if (
            hierarchyQuestion.fids[answeredId] &&
            hierarchyQuestion.fids[answeredId].length > 0
          ) {
            const nestedIds = hierarchyQuestion.fids[answeredId];
            depth += 1;
            tree = insertQuestionsIntoTree(nestedIds, tree, depth);
            zeroIndex = true;
          }
        }

        getNextQuestion(tree, depth, zeroIndex);
        return;
      }
      formatAndSetCurrentQuestion(hierarchyQuestion);
      return;
    }

    // move depth up
    getNextQuestionDepthUp(tree, depth);
  };

  const getNextQuestionDepthUp = (tree: QuestionTree, depth: number) => {
    if (depth === 0) {
      dispatch({
        type: "set-finished",
        payload: true,
      });
      wrapperRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }

    const newDepth = depth - 1;
    const nextIndex = tree[newDepth].index + 1;
    if (tree[newDepth].list[nextIndex]) {
      const hierarchyQuestion = tree[newDepth].list[nextIndex];
      if (contractData.answersLog[hierarchyQuestion.id]) {
        getNextQuestion(tree, newDepth);
        return;
      }
      formatAndSetCurrentQuestion(hierarchyQuestion);
      tree[newDepth].index = nextIndex;

      dispatch({
        type: "current-depth",
        payload: newDepth,
      });

      dispatch({
        type: "question-tree",
        payload: tree,
      });
      return;
    }
    getNextQuestionDepthUp(tree, newDepth);
  };

  const insertQuestionsIntoTree = (
    ids: Array<string>,
    tree: QuestionTree,
    depth: number
  ): QuestionTree => {
    const hierarchyQuestions: Array<HierarchyItem> = [];
    ids.map((id) => {
      for (const [k, v] of Object.entries(data.hierarchy[depth])) {
        if (k === id) {
          hierarchyQuestions.push(v);
        }
      }
    });

    tree[depth].list = hierarchyQuestions;
    tree[depth].index = 0;

    dispatch({
      type: "current-depth",
      payload: depth,
    });

    dispatch({
      type: "question-tree",
      payload: tree,
    });

    return tree;
  };

  const renderQuestion = () => {
    if (editQuestionId) {
      return renderEditQuestion();
    }
    if (!contractData.currentQuestion) {
      return;
    }

    return (
      <Question
        key={contractData.currentQuestion.q._id}
        question={contractData.currentQuestion.q}
        submitQuestionData={submitQuestionData}
        variables={variables}
        setVariables={setVariables}
        renderTitleText={renderTitleText}
      />
    );
  };

  const renderTitleText = (title: string) => {
    if (Object.entries(variables).length === 0) {
      return title;
    }

    const reg = RegExp("\\[([\\w\\d\\s]+)\\]", "g");
    for (const match of title.matchAll(reg)) {
      const key = match[1].split(" ").join("").toLowerCase();
      title = title.replace(`[${match[1]}]`, variables[key]);
    }
    return title;
  };

  const renderEditQuestion = (questionId?: string) => {
    const qID = questionId ?? editQuestionId;

    const question = getQuestionById(qID);
    if (!question) return null;

    const answerFields = contractData.answersLog[qID]?.fields;
    return (
      <QuestionEditWrapper
        key={`${qID}-edit`}
        onClose={() => {
          setEditQuestionId(undefined);
        }}
      >
        <Question
          key={qID}
          question={question}
          submitQuestionData={() => ""}
          variables={variables}
          setVariables={setVariables}
          renderTitleText={renderTitleText}
          editMode={true}
          values={answerFields}
          updateQuestionData={(a: AnswerData[], id: string) => {
            updateQuestionData(a, id);
            setEditQuestionId(undefined);
          }}
        />
      </QuestionEditWrapper>
    );
  };

  const renderQuestionSummary = (isWidget = false) => {
    return (
      <QuestionSummary
        getQuestionById={getQuestionById}
        answersLog={contractData.answersLog}
        isWidget={isWidget}
        renderTitleText={renderTitleText}
        renderEditQuestion={renderEditQuestion}
        editQuestionId={editQuestionId}
        setEditQuestionId={setEditQuestionId}
      />
    );
  };

  return (
    <>
      {contractData.finished ? (
        <div className="">
          <div className="mx-auto lg:max-w-4xl">
            <h1 className="mb-2 text-[18px] leading-none text-dark-blue lg:text-[24px]">
              Har vi förstått dig rätt?
            </h1>
            <div className="mb-8 border-b-[1px] border-[#E7E4D9] pb-4 font-interlight text-[14px] italic">
              Gå igenom dina svar noggrant:
            </div>
            <div className="mb-8">
              <UserInfo />
            </div>
            {renderQuestionSummary()}
            <div className="mb-2 flex flex-col items-center justify-between gap-4">
              <Button
                title={`spara & skapa ${contract.title}`}
                onClick={submitAnswers}
              />
              <button
                type="button"
                onClick={() => window.location.reload()}
                className="flex flex-row gap-3 font-intermedium uppercase leading-tight tracking-widest text-dark-blue"
              >
                {getRestartIcon("#0C2632", "16px", "100%")}
                <span className="border-b-[1px] border-dark-blue">
                  börja om från början
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-grow flex-col gap-6 lg:flex-row">
            <div className="relative flex-grow">{renderQuestion()}</div>
            <div className="relative lg:w-[340px]">
              <UserInfo />
              {renderQuestionSummary(true)}
            </div>
          </div>
        </>
      )}
    </>
  );
}
