import React, { useEffect, useRef, useState } from "react";

const video = new URL("../assets/video/adv.mp4", import.meta.url);
const videoThumb = new URL("../assets/video/adv.jpg", import.meta.url);

const TutorialVideo = () => {
  const [play, setPlay] = useState(false);
  const refVideo = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!refVideo.current) {
      return;
    }

    if (play) {
      void refVideo.current.play();
    } else {
      refVideo.current.pause();
    }
  }, [play]);

  const togglePlay = () => {
    setPlay(!play);
  };

  const getPlayPauseIcon = () => {
    if (play) {
      return (
        <svg
          width="100"
          height="100"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M384 810.666667c-25.6 0-42.666667-17.066667-42.666667-42.666667L341.333333 256c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 512C426.666667 793.6 409.6 810.666667 384 810.666667z"
            fill="white"
          />
          <path
            d="M640 810.666667c-25.6 0-42.666667-17.066667-42.666667-42.666667L597.333333 256c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 512C682.666667 793.6 665.6 810.666667 640 810.666667z"
            fill="white"
          />
        </svg>
      );
    }
    return (
      <svg
        width="40"
        height="48"
        viewBox="0 0 46 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.5 23.9019C46.5 25.0566 46.5 27.9434 44.5 29.0981L4.75 52.0477C2.75 53.2024 0.249997 51.7591 0.249998 49.4497L0.25 3.55033C0.25 1.24093 2.75 -0.202453 4.75 0.952248L44.5 23.9019Z"
          fill="white"
        />
      </svg>
    );
  };

  return (
    <div className="group relative">
      <div
        className="absolute z-10 flex h-full w-full flex-col items-center justify-center"
        onClick={togglePlay}
      >
        <div
          className={`flex h-[80px] w-[80px] cursor-pointer flex-col items-center justify-center rounded-full ${
            !play ? "opacity-80" : "opacity-0"
          } transition group-hover:opacity-80`}
          style={{ background: "rgba(0,0,0,.5)" }}
        >
          {getPlayPauseIcon()}
        </div>
      </div>
      <video
        ref={refVideo}
        playsInline
        loop
        src={video.href}
        className="object-fit mx-auto h-full w-full rounded-[8px]"
        style={{ transform: "scale(1.0)" }}
        preload="auto"
        poster={videoThumb.href}
      />
    </div>
  );
};

export default TutorialVideo;
