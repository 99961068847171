import React, {
  createRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import {
  useConvContext,
  useConvDispatchContext,
} from "../../context/ConversationContext";
import { useUserContext } from "../../context/UserContext";
import { conversationData } from "../../services/conversation";
import {
  getCookie,
  partnerKey,
  setInStorage,
  userEmailKey,
} from "../../services/storage";
import { sendCmpEvent } from "../../services/tracking";
import { isDesktopView } from "../../services/utils";
import BankIdLoginButton from "../BankIdLoginButton";
import PageHead from "../PageHead";
import Item from "./Item";
import Package from "./Package";
import Recommendation from "./Recommendation";
import Start from "./Start";
import ConversationSummary from "./Summary";

const conversationDataRefs = conversationData.reduce((acc, currentValue) => {
  acc[currentValue.id] = createRef();
  return acc;
}, {});

const List = ({
  showHead = true,
  embeddedMode = false,
  convHeightClass = "lg:max-h-[65vh]",
  sumHeightClass = "",
  startHeightClass = "",
}) => {
  const [userEmail, setUserEmail] = useState();
  const scrollRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const embeddedRef = useRef<HTMLDivElement>(null);

  const { user, updateProfile } = useUserContext();
  const { conversation, nextItem } = useConvContext();
  const dispatch = useConvDispatchContext();

  useEffect(() => {
    if (userEmail) {
      const pc = getCookie(partnerKey);
      if (pc) {
        void sendCmpEvent(`Partner_${pc.toString()}`, userEmail);
      }
      void sendCmpEvent("Mail_1", userEmail);
      if (user.accessToken && user.profile && user.profile.email === null) {
        updateProfile(user.accessToken, { ...user.profile, email: userEmail });
      }
      if (embeddedMode) {
        setInStorage(userEmailKey, userEmail);
      }
    }
  }, [userEmail]);

  useEffect(() => {
    if (conversation.status === "init" || conversation.status === "started") {
      nextItem();
    }
  }, [conversation.values]);

  useEffect(() => {
    if (conversation.status === "finished" && embeddedMode) {
      embeddedRef.current?.scrollIntoView({
        // behavior: "auto",
        // block: "end",
      });
    }
  }, [conversation.status]);

  useEffect(() => {
    if (!conversation.values) {
      return;
    }

    const emailData = conversation.values.find((el) => el.name === "email");
    if (!emailData) {
      return;
    }

    const email = emailData.value[0];
    if (email) {
      setUserEmail(email);
    }
  }, [conversation.values]);

  useEffect(() => {
    if (isDesktopView()) {
      return;
    }
    if (!embeddedMode) {
      return;
    }
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, [conversation.items, conversation.status]);

  const scrollContainerToBottom = () => {
    if (!isDesktopView()) {
      return;
    }
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current?.offsetHeight;
    }
  };

  const renderStartedList = () => {
    return (
      <>
        {showHead && (
          <PageHead
            title={user.info ? `Bra ${user.info.firstName},` : "Bra"}
            subtitle="vi har kommit en bra bit på vägen!"
            icon="chat"
          />
        )}

        <div
          className={`relative grow overflow-auto ${
            embeddedMode ? "" : "lg:mt-4"
          }`}
        >
          <div
            className={`shadow-default relative mx-auto flex h-full w-full max-w-[560px] flex-col justify-between rounded-lg ${convHeightClass}`}
          >
            {conversation.items.length > 3 && (
              <div
                className="absolute top-0 left-0 z-10 h-[60px] w-full bg-gradient-to-b from-white to-transparent"
                style={{
                  background:
                    "-webkit-linear-gradient(top, white, rgba(255,255,255,0.01))",
                }}
              ></div>
            )}
            <div
              className="conv-list flex flex-1 flex-col-reverse overflow-y-auto px-4 pt-5 pb-[5px] lg:p-5"
              ref={containerRef}
            >
              <div ref={scrollRef} className="h-[0px] shrink" />
              {conversation.items.map((item, index) => (
                <Item
                  ref={conversationDataRefs[item.id]}
                  key={item.id}
                  item={item}
                  scrollContainerToBottom={scrollContainerToBottom}
                  embeddedMode={embeddedMode}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  if (embeddedMode) {
    return (
      <div className="p-[15px] lg:h-full" ref={embeddedRef}>
        {conversation.status === "init" && (
          <Start
            showHead={showHead}
            embeddedMode={embeddedMode}
            heightClass={startHeightClass}
          />
        )}
        {conversation.status === "started" && renderStartedList()}
        {["finished", "recommendation", "packet"].includes(
          conversation.status
        ) && (
          <ConversationSummary
            showHead={showHead}
            embeddedMode={embeddedMode}
            heightClass={sumHeightClass}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {conversation.status === "init" && <Start />}
      {conversation.status === "started" && renderStartedList()}
      {conversation.status === "finished" && <ConversationSummary />}
      {conversation.status === "recommendation" && (
        <>
          <Recommendation
            onFinish={() => {
              if (user.profile) {
                void sendCmpEvent("Mail_2", user.profile.email);
              }
              dispatch({
                type: "set-status",
                payload: { status: "packet", accessToken: user.accessToken },
              });
            }}
          />
        </>
      )}
      {conversation.status === "packet" && (
        <>
          <>
            <PageHead
              title={
                user.info
                  ? `${user.info.firstName}'s Lugn&Ro-paket`
                  : "Lugn&Ro-paket"
              }
              subtitle="Detta ingår"
              icon="chat"
            />
            <Package />
          </>
        </>
      )}
    </>
  );
};

export default List;
