import React from "react";

import { useServiceSectionContext } from "../context/ServiceSectionContext";
import { getButtonArrow, getCloseIcon } from "../services/icons";
import { isDesktopView } from "../services/utils";

type ServiceType = {
  id: number;
  image: URL;
  title: string;
  desc: string;
  text: string;
};
type ServicePropsType = {
  service: ServiceType;
  onClick: (d: number, m: boolean) => void;
};

export const services: ServiceType[] = [
  {
    id: 1,
    image: new URL("../assets/images/h1.jpg", import.meta.url),
    title: "Sparande",
    desc: "Vare sig du planerar att spara på kort eller lång sikt kan vi, tillsammans med våra partners, hjälpa dig att trygga framtiden för dig och dom du håller kär.",
    text: "<p class='mb-5'>Tillsammans går vi igenom din livssituation, dina behov och önskemål och ger en rekommendation på konkreta åtgärder inom sparande på lång- och kort sikt som våra partners dessutom kan hjälpa dig med.</p><p>Vår erfarenhet säger oss nämligen att planering, ordning och reda skapar förutsättningar för att kunna leva ett rikt liv.</p>",
  },
  {
    id: 2,
    image: new URL("../assets/images/h2.jpg", import.meta.url),
    title: "Juridik",
    desc: "Skapa avtal som testamenten och äktenskapsförord för att underlätta för dig själv och dina närmaste.",
    text: "Vi förstår vikten av att hjälpa familjer med juridik på ett tydligt och korrekt vis. Därför erbjuder vi juridiska tjänster som testamente, äktenskapsförord, framtidsfullmakt och andra avtal. Så att livet kan bli lättare för dig här och nu och i framtiden.",
  },
  {
    id: 3,
    image: new URL("../assets/images/h3.jpg", import.meta.url),
    title: "Försäkringar",
    desc: "Ta aldrig din försäkringssituation för given. Det kan snabbt bli en dyr historia om du själv, dina barn eller din fyrbenta vän inte är rätt försäkrad.",
    text: "Det finns en mängd saker man borde göra men som man drar sig för att ta tag i. Tillsammans med våra partners kan vi erbjuda dig rådgivning kring vad just du behöver, varken mer eller mindre, för att kunna känna dig trygg.",
  },
];

const CompactService = ({ service, onClick }: ServicePropsType) => {
  return (
    <div
      className="relative cursor-pointer overflow-hidden rounded-bl-[16px] rounded-br-[16px]"
      onClick={() => onClick(service.id, false)}
    >
      <div
        className="absolute bottom-0 w-full rounded-bl-[16px] rounded-br-[16px] p-6 text-white backdrop-blur"
        style={{ WebkitBackdropFilter: "blur(8px)" }}
      >
        <div className="uppercase tracking-[0.4em] text-[#FFEEC1]">
          {service.title}
        </div>
        <div className="my-4 min-h-[50px] max-w-[370px] text-[13px] leading-tight">
          {service.desc}
        </div>
        <div className="flex flex-row items-center gap-1">
          Läs mer {getButtonArrow("#ffffff", "20", "100%")}
        </div>
      </div>
      <img
        src={service.image.href}
        className="rounded-tl-[3px] rounded-tr-[3px] rounded-bl-[16px] rounded-br-[16px]"
      />
    </div>
  );
};

const ExpandedService = ({ service, onClick }: ServicePropsType) => {
  return (
    <div
      className="relative flex flex-col-reverse overflow-hidden rounded-[8px] bg-[#f3f1e8] text-dark-blue lg:grid lg:grid-cols-2 lg:flex-row"
      id={`el-${service.id}`}
    >
      <div className="flex-1 p-6 lg:p-10 xl:p-20">
        <h2 className="mb-3 font-interbold text-[32px] italic leading-none lg:mb-5 lg:text-[48px]">
          {service.title}
        </h2>
        <div
          className="max-w-lg font-interlight"
          dangerouslySetInnerHTML={{
            __html: service.text,
          }}
        />
      </div>
      <div className="relative h-full flex-1 overflow-hidden rounded-t-[8px] lg:block lg:max-h-[520px] lg:rounded-r-[8px] lg:rounded-t-[0]">
        <div
          className="absolute top-5 right-5 z-10 flex h-[36px] w-[36px] cursor-pointer justify-center rounded-full bg-white bg-opacity-80"
          onClick={() => onClick(0, true)}
        >
          {getCloseIcon("#525252", "18", "100%")}
        </div>
        <img
          src={service.image.href}
          alt="Fenix"
          className="relative w-full lg:top-[50%] lg:translate-y-[-50%]"
          style={{
            transform: `${isDesktopView() ? "translateY(-50%)" : "none"}`,
          }}
        />
      </div>
    </div>
  );
};

export default function ServicesSection() {
  const { toggleMode, compactMode } = useServiceSectionContext();

  if (compactMode) {
    return (
      <div
        id="services-list"
        className="mb-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:mb-20 lg:grid-cols-3"
      >
        {services.map((service) => (
          <CompactService
            key={service.id}
            service={service}
            onClick={toggleMode}
          />
        ))}
      </div>
    );
  }
  return (
    <div className="mb-10 flex flex-col gap-8 lg:mb-20">
      {services.map((service) => (
        <ExpandedService
          key={service.id}
          service={service}
          onClick={toggleMode}
        />
      ))}
    </div>
  );
}
