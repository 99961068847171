import React from "react";

import Button from "./Button";
import TestimonialVideo from "./TestimonialVideo";

const TestimonialSection = ({
  buttonOnClick,
}: {
  buttonOnClick: () => void;
}) => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-col justify-center gap-5 lg:flex-row lg:gap-10">
        <div>
          <div className="shrink-0 overflow-hidden rounded-[8px]">
            <TestimonialVideo />
          </div>
        </div>
        <div className="flex max-w-2xl grow flex-col justify-between">
          <div className="mb-4 bg-quote bg-[length:120px_100px] bg-[top_left] bg-no-repeat pt-[58px] pl-4 font-interlight text-[24px] italic lg:mb-6 lg:bg-[length:156px_130px] lg:pl-5 lg:pt-[74px] lg:text-[36px]">
            Jag vill liksom bara fortsätta som det alltid har varit, jag älskar
            livet och vill bara lägga allt mitt fokus på att leva det nu och
            sen, det är ju det jag vill för min dotter också...
          </div>
          <div className="mb-5 max-w-[340px] pl-4 font-intermedium text-[18px] italic lg:mb-0 lg:text-[22px]">
            Robert, pappa till Cordelia och Leonbergern Maui
          </div>
          <div className="inline-block pl-4">
            <Button
              onClick={buttonOnClick}
              title="gör som robert"
              id="testimonial-login"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
