import React, { FormEvent, useEffect, useState } from "react";

import {
  dataDeepCopy,
  generateRandomString,
  isFieldValid,
} from "../../../services/utils";
import {
  AnswerData,
  AnswersLogItem,
  ErrorFields,
  FieldItem,
  VariablesList,
} from "../../../types/entities";
import Button from "../../Button";
import ErrorFormMessage from "../ErrorFormMessage";
import DatePicker from "./DatePicker";
import Input from "./Input";

type Props = {
  fields: FieldItem[];
  onSubmit: (d: AnswerData[]) => void;
  variables: VariablesList;
  setVariables?: React.Dispatch<React.SetStateAction<VariablesList>>;
  renderTitleText: (t: string) => string;
  values?: AnswersLogItem;
  editMode?: boolean;
};

export default function InputFields({
  fields,
  onSubmit,
  variables,
  setVariables,
  renderTitleText,
  values,
  editMode,
}: Props) {
  const [errorFields, setErrorFields] = useState({} as ErrorFields);
  const [liveValidation, setLiveValidation] = useState(false);
  const [data, setData] = useState([] as AnswerData[]);
  const [localVars, setLocalVars] = useState({} as VariablesList);

  useEffect(() => {
    const v = [] as AnswerData[];
    fields.map((el) => {
      v.push({
        id: el._id,
        value: values && values[el._id] ? values[el._id].value.toString() : "",
      });
    });
    setData(v);
  }, []);

  const submitData = (
    e: React.SyntheticEvent,
    variable: null | { name: string; value: string } = null
  ) => {
    const target = e.target as typeof e.target & AnswerData;

    const value = target.value;
    const id = target.id;

    const dt: AnswerData[] = dataDeepCopy(data);
    const index = dt.findIndex((el) => {
      return el.id === id;
    });

    dt[index].value = value;
    setData(dt);
    if (liveValidation) {
      validateData(id, dt);
    }

    if (variable) {
      const vars = structuredClone(localVars);
      vars[variable.name] = variable.value;
      setLocalVars(vars);
    }
  };

  const validateData = (id = "", data: AnswerData[]) => {
    const errors = {} as ErrorFields;

    if (id) {
      const v = data.filter((el) => {
        return el.id === id;
      });
      const val = v[0];
      errors[id] = isFieldValid(val.value, getTypeForField(id));
    } else {
      data.map((el) => {
        errors[el.id] = isFieldValid(
          el.value.toString(),
          getTypeForField(el.id)
        );
      });
    }

    const resOut = {} as ErrorFields;
    for (const [k, v] of Object.entries({ ...errorFields, ...errors })) {
      if (v !== true) {
        resOut[k] = v;
      }
    }
    setErrorFields(resOut);
    return resOut;
  };

  const getTypeForField = (id: string) => {
    const fieldFiltered = fields.filter((el) => el._id === id);
    const field = fieldFiltered.length > 0 ? fieldFiltered[0] : null;
    if (!field) {
      return "text";
    }
    if (field.format && field.format === "percentage") {
      return "range";
    }

    const preparedLabel = field.label.trim().toLowerCase();
    if (preparedLabel.search("personnummer") > -1) {
      return "personnummer";
    }
    return "text";
  };

  const clickNext = () => {
    setLiveValidation(true);
    const res = validateData("", data);
    if (Object.entries(res).length > 0) {
      return;
    }

    const mergedVars: VariablesList = { ...variables, ...localVars };
    if (setVariables) {
      setVariables(mergedVars);
    }
    onSubmit(data);
  };

  return (
    <div className="flex flex-col gap-5 lg:gap-10">
      <div>
        {fields.map((el) => {
          const hasError = errorFields[el._id] ?? false;
          let fieldValue = "";

          if (el.format && el.format === "percentage") {
            return (
              <div key={el._id} className="max-w-xl">
                <label
                  id={`label-${el._id}`}
                  htmlFor={el._id}
                  className="mb-4 block text-sm font-medium leading-[22px] text-gray-900"
                >
                  {el.label}
                </label>
                <input
                  id={el._id}
                  type="range"
                  min="0"
                  max="100"
                  onChange={submitData}
                  onInput={(e) => {
                    const doc = document.getElementById(`label-${el._id}`);
                    if (doc) {
                      const target = e.target as HTMLInputElement;
                      doc.innerHTML = `${el.label} <span style="font-family: InterBold; font-size: 18px; line-height: 1;">${target.value}%</span>`;
                    }
                  }}
                  className="range-lg h-[4px] w-full cursor-pointer appearance-none rounded-lg bg-black"
                  style={{ WebkitAppearance: "none", MozAppearance: "none" }}
                />
                {hasError ? (
                  <ErrorFormMessage message="Du måste ange ett värde för att kunna gå vidare" />
                ) : null}
              </div>
            );
          }
          if (el.format && el.format === "date") {
            return (
              <DatePicker
                key={el._id}
                id={el._id}
                onSubmit={submitData}
                error={hasError}
              />
            );
          }

          const obj = data.filter((e) => {
            return e.id === el._id;
          });
          if (obj.length > 0) {
            fieldValue = obj[0].value;
          }

          return (
            <div key={el._id} className="mb-3 md:mb-8">
              <Input
                type="text"
                name={el._id}
                onChange={(e) => {
                  if (el.format && el.format === "variable") {
                    submitData(e, {
                      name: el.label.split(" ").join("").toLowerCase(),
                      value: e.target.value,
                    });
                  } else {
                    submitData(e);
                  }
                }}
                label={renderTitleText(el.label)}
                error={hasError}
                value={fieldValue}
              />
            </div>
          );
        })}
      </div>
      <div className={editMode ? "self-center" : "self-end"}>
        <Button
          theme="dark"
          title={editMode ? "Ändra" : "Nästa fråga"}
          onClick={clickNext}
          rightArrow={false}
        />
      </div>
    </div>
  );
}
