import React from "react";

const aboutUsImg = new URL("../assets/images/omfenix.jpg", import.meta.url);

export default function AboutUs() {
  return (
    <div className="container mx-auto bg-[#FFFEFB] px-5">
      <div className="flex flex-col flex-col-reverse rounded-[8px] bg-[#f3f1e8] text-dark-blue lg:flex-row">
        <div className="p-6 lg:p-10 xl:p-20">
          <h2 className="mb-3 font-interbold text-[32px] italic leading-none lg:mb-5 lg:text-[48px]">
            Fenix Family
          </h2>
          <div className="font-interlight">
            <div className="mb-5">
              Fenix Family föddes ur Fenix Begravning där vi ofta fick frågor om
              andra saker än själva begravningsceremonin. För i livets slutskede
              ställs ofta ekonomi, juridik och hur man planerat både sitt liv
              och framtiden för sina närmaste, på sin spets. Idag kan vi hjälpa
              alla som vill skapa testamenten, äktenskapsförord, avtal och
              fullmakter, med en enkel, trygg och digital lösning på problem som
              ofta kan upplevas som känsliga och svåra.
            </div>
            <div className="mb-5">Välkommen till Fenix Family</div>
            <div>Charlotte, Johannes och Mikael</div>
          </div>
        </div>
        <div className="flex overflow-hidden rounded-t-[8px] lg:block lg:max-h-fit lg:overflow-visible lg:rounded-r-[8px]">
          <img
            src={aboutUsImg.href}
            alt="Fenix"
            className="relative h-auto w-[100%] rounded-t-xl lg:h-fit lg:h-full lg:w-auto lg:max-w-fit lg:rounded-l-none lg:rounded-r-[8px]"
          />
        </div>
      </div>
    </div>
  );
}
