import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { useUserContext } from "../context/UserContext";
import {
  getFenixLogo,
  getLogInIcon,
  getLogOutIcon,
  getVaultLogo,
} from "../services/icons";

export default function HeadPartner({
  partner,
}: {
  partner?: { title: string; logo: URL };
}) {
  const { user, logout } = useUserContext();
  const navigate = useNavigate();

  const performNavigate = (to, check = true) => {
    if (to === "logout") {
      logout();
    } else {
      navigate(to);
    }
  };

  const textColor = "text-dark-blue";
  const iconsColor = "#002733";

  const renderLoginButton = () => {
    return (
      <Link
        to="/login"
        className="flex flex-row items-center gap-2 self-center border-[1px] border-transparent p-2 lg:gap-3 lg:p-4"
      >
        {getLogInIcon(iconsColor)}
        <span
          className={`font-intermedium text-[14px] uppercase leading-none tracking-widest ${textColor}`}
        >
          logga in
        </span>
      </Link>
    );
  };

  return (
    <div className="my-4 flex w-full flex-col items-center justify-between gap-3 lg:my-8 lg:flex-row lg:gap-0">
      <div className="flex flex-row items-end gap-5">
        <a
          onClick={() => performNavigate("/")}
          className="w-[150px] cursor-pointer lg:w-[200px]"
        >
          {getFenixLogo(iconsColor, "100%", "100%")}
        </a>
        <div className="hidden self-end uppercase leading-none tracking-[0.1em] text-dark-blue lg:flex lg:flex-grow lg:gap-2">
          <div className="flex flex-grow gap-2">
            <span className="cursor-default">SPARANDE</span>+
          </div>
          <div className="flex flex-grow gap-2">
            <span className="cursor-default">JURIDIK</span>+
          </div>
          <div className="flex flex-grow gap-2">
            <span className="cursor-default">FÖRSÄKRINGAR</span>
          </div>
        </div>
      </div>
      {partner?.logo && (
        <div className="relative">
          <div className="relative mb-[-8px] mt-[14px]">
            <div className="absolute right-0 top-[-12px] text-right text-[12px] italic text-dark-blue lg:top-[-17px] lg:text-[18px]">
              I samarbete med
            </div>
            <img src={partner.logo.href} className="w-[180px] lg:w-[250px]" />
          </div>
        </div>
      )}
      {!partner?.logo && !user?.info && renderLoginButton()}
      {!partner?.logo && user?.info && (
        <div
          className={`flex flex-row items-center gap-0 self-center text-[14px] ${textColor} lg:gap-3`}
        >
          {user.package && (
            <a
              onClick={() => performNavigate("/vault")}
              className="flex cursor-pointer flex-row items-center gap-2 font-intermedium uppercase leading-none tracking-widest lg:gap-3"
            >
              {getVaultLogo(iconsColor, "20", "100%")}
              <span className="hidden lg:inline">DITT KASSASKÅP</span>
            </a>
          )}

          <div className="flex flex-row items-center gap-2 border-[1px] border-transparent p-2 pr-0 leading-none lg:gap-3 lg:p-4">
            {getLogOutIcon(iconsColor)}
            <span
              className="cursor-pointer font-intermedium uppercase tracking-widest"
              onClick={() => performNavigate("logout")}
            >
              logga ut
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
