import React, { useEffect, useState } from "react";

import { isFieldValid } from "../../../services/utils";
import Button from "../../Button";
import ErrorFormMessage from "../ErrorFormMessage";

export default function Radio({
  fields,
  onSubmit,
  renderTitleText,
  values,
  editMode,
}) {
  const [error, setError] = useState();
  const [data, setData] = useState();
  let errorClass = "";

  useEffect(() => {
    if (!values) {
      return;
    }
    let v = {};
    fields.map((el) => {
      if (values[el._id] && values[el._id].value.toString() === "1") {
        v = { id: el._id, value: values[el._id].value.toString() };
      }
    });
    setData(v);
  }, []);

  const submitData = (e) => {
    setError(false);

    const value = e.target.value;
    const id = e.target.id;
    if (!value || value === "") {
      return;
    }
    setData({ id: id, value: value });
  };

  const clickNext = () => {
    const check = isFieldValid(data, "radio");
    if (check !== true) {
      setError(check);
      return;
    }

    onSubmit([data]);
  };

  if (error) {
    errorClass = "border-red-500 text-red-500";
  }

  return (
    <div className="flex flex-col gap-6 lg:gap-12">
      <div className="flex flex-col gap-1 lg:gap-3">
        {fields.map((el) => {
          return (
            <div key={el._id} className="flex items-start gap-2">
              <label
                className="relative flex cursor-pointer items-center rounded-full p-1"
                htmlFor={el._id}
              >
                <input
                  id={el._id}
                  type="radio"
                  value="1"
                  name="default-radio"
                  onChange={submitData}
                  checked={data?.id === el._id}
                  style={{ WebkitAppearance: "none", MozAppearance: "none" }}
                  className={`before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-[#CFCAB8] text-[#CFCAB8] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-6 before:w-6 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-[#CFCAB8] before:opacity-0 before:transition-opacity checked:border-[#CFCAB8] checked:before:bg-[#CFCAB8] hover:before:opacity-10 ${errorClass}`}
                />
                <div
                  className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-[#CFCAB8] opacity-0 transition-opacity peer-checked:opacity-100"
                  style={{ transform: "translateY(-50%) translateX(-50%)" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                  >
                    <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                  </svg>
                </div>
              </label>

              <label
                htmlFor={el._id}
                className={`cursor-pointer select-none self-center pt-[2px] font-internormal leading-tight text-dark-blue lg:text-[18px] ${errorClass}`}
              >
                {renderTitleText(el.label)}
              </label>
            </div>
          );
        })}
        {error ? <ErrorFormMessage message={error} /> : null}
      </div>
      <div className={editMode ? "self-center" : "self-end"}>
        <Button
          theme="dark"
          title={editMode ? "Ändra" : "Nästa fråga"}
          onClick={clickNext}
          rightArrow={false}
        />
      </div>
    </div>
  );
}
