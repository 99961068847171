import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import LoaderScreen from "../components/LoaderScreen";
import { useUserContext } from "../context/UserContext";
import { contracts } from "../services/contracts";
import { getProfileInfo, getUserData, getUserPackage } from "../services/ida";

export default function DebugPage() {
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState();
  const [packageData, setPackageData] = useState();
  const [dataStore, setDatastore] = useState();

  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.accessToken) {
      setLoading(true);
      const profilePromise = getProfileInfo(user.accessToken)
        .then((data) => {
          setRawData(data);
        })
        .catch((e) => console.log(e));

      const packagePromise = getUserPackage(user.accessToken)
        .then((data) => {
          setPackageData(data);
        })
        .catch((e) => console.log(e));

      const datastorePromise = getUserData(user.accessToken)
        .then((data) => {
          setDatastore(data);
        })
        .catch((e) => console.log(e));

      Promise.allSettled([
        profilePromise,
        datastorePromise,
        packagePromise,
      ]).then((values) => {
        setLoading(false);
      });

      return;
    }
  }, [user.accessToken]);

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      <div className="mx-auto max-w-xl text-center">
        {user?.info && (
          <div>
            <div className="break-words text-left text-[14px]">
              <div>Access token:</div>
              <code className="text-[12px]">{user.accessToken}</code>
            </div>
            {rawData && (
              <div className="mt-10 whitespace-pre-wrap text-left leading-none">
                <code className="text-[12px]">
                  GET /api/v1/public/customer/profile
                  <br />
                  {JSON.stringify(rawData, null, 4)}
                </code>
              </div>
            )}
            {dataStore && (
              <div className="mt-10 whitespace-pre-wrap text-left leading-none">
                <code className="text-[12px]">
                  GET /api/v1/public/customer/datastore
                  <br />
                  {JSON.stringify(dataStore, null, 4)}
                </code>
              </div>
            )}
            {packageData && (
              <div className="mt-10 whitespace-pre-wrap text-left leading-none">
                <code className="text-[12px]">
                  GET /api/v1/public/customer/package
                  <br />
                  {JSON.stringify(packageData, null, 4)}
                </code>
              </div>
            )}
          </div>
        )}
      </div>
      <hr className="my-5 lg:my-10" />
      <div>
        <Button
          onClick={() => navigate("/conversation")}
          title="Conversational flow"
        />
        <hr className="my-5 lg:my-10" />
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-3">
          {contracts.map((el) => {
            if (el.visible === 0) {
              return null;
            }
            const url = `/contract/${el.id}`;
            return (
              <div key={el.id} className="flex flex-col text-dark-blue">
                <Link
                  to={url}
                  className="imp-arg-title mb-3 font-interbold text-[18px] leading-tight lg:text-[24px]"
                >
                  {el.title}
                </Link>
                <div className="mb-5 text-sm">{el.home}</div>
                <div>
                  <Button
                    title="Visa mer"
                    size="small"
                    onClick={() => navigate(url)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
