import "../assets/styles/stripe.scss";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AboutUs from "../components/AboutUs";
import Button from "../components/Button";
import ContactUs from "../components/ContactUs";
import ConversationEmbedded from "../components/ConversationEmbedded";
import HeadPartner from "../components/HeadPartner";
import LoaderScreen from "../components/LoaderScreen";
import PartnerStepsSection from "../components/PartnerStepsSection";
import TestimonialSection from "../components/TestimonialSection";
import TrustBox from "../components/TrustBox";
import TutorialVideo from "../components/TutorialVideo";
import { getCheckmarkIcon } from "../services/icons";
import { getPartnerByUrl } from "../services/partners";
import {
  frPkgKey,
  homeRedirectKey,
  partnerKey,
  setCookie,
  setInStorage,
} from "../services/storage";
import { isDesktopView } from "../services/utils";

const phoneIcon = new URL("../assets/images/phone.png", import.meta.url);

export default function Index() {
  const [partner, setPartner] = useState();
  const [loading, setLoading] = useState(true);
  const [showConversationModal, setShowConversationModal] = useState(false);
  const navigate = useNavigate();
  const { pt } = useParams<{
    pt: string;
  }>();

  useEffect(() => {
    setInStorage(homeRedirectKey, window.location.pathname);

    if (pt) {
      const partnerExist = getPartnerByUrl(pt);
      if (partnerExist) {
        setCookie(partnerKey, pt);
        setPartner(partnerExist);
      } else {
        navigate("/partner");
        setLoading(false);
      }
    } else {
      setPartner(null);
    }

    const frPkgParam = new URLSearchParams(window.location.search).get("fp");
    if (frPkgParam && frPkgParam === frPkgKey) {
      setCookie(frPkgKey, frPkgKey);
    }
  }, []);

  useEffect(() => {
    if (partner !== undefined) {
      setLoading(false);
    }
  }, [partner]);

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      <div className="overflow-x-clip px-5">
        <div className="container mx-auto">
          <HeadPartner partner={partner} />
        </div>
        <div className="container relative mx-auto">
          <div
            style={{
              background: "linear-gradient(103.9deg, #f3f2ee, #f9f8f4)",
            }}
            className="relative z-10 rounded-t-lg text-dark-blue"
          >
            <div className="relative z-10 rounded-t-lg p-5 py-10 lg:p-14">
              <div className="mb-2 text-center font-interbold text-[24px] italic leading-tight lg:max-w-[1000px] lg:text-left lg:text-[54px]">
                Kombinationen är rekommendationen
              </div>
              <div className="max-w-[600px] text-center text-[13px] lg:text-left lg:text-[22px] xl:max-w-[730px]">
                Fenix livsplanering är din personliga kombination av juridiska
                avtal, sparande och försäkringar – planera för en framtid precis
                som du vill ha den. Allt sparas tryggt och säkert i ditt
                digitala kassaskåp.
              </div>
              <div className="my-12 flex flex-col items-center gap-3 lg:ml-6 lg:items-start">
                {[
                  "Färre familjekonflikter",
                  "Tryggt och enkelt",
                  "Lugn och ro",
                ].map((el) => {
                  let iconWidth = 28;
                  if (!isDesktopView()) {
                    iconWidth = 20;
                  }
                  return (
                    <div
                      key={el.length}
                      className="flex flex-row items-center gap-2 lg:gap-6"
                    >
                      <div>
                        {getCheckmarkIcon("#51B380", iconWidth, "100%")}
                      </div>
                      <span className="font-intermedium text-[13px] uppercase tracking-[0.18em] lg:text-[18px]">
                        {el}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-center lg:justify-start">
                <div>
                  {/*<BankIdLoginButton id="login-partner" title="börja här" />*/}
                  <Button
                    onClick={() => setShowConversationModal(true)}
                    title="börja här"
                    id="conv-start-partner"
                  />
                  <div className="mt-2 text-center text-[14px] italic tracking-tight lg:text-left">
                    Det tar ca 5 minuter och är kostnadsfritt
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={phoneIcon.href}
            className="absolute z-10 hidden w-[300px] lg:right-[-10px] lg:bottom-[-40px] lg:block xl:bottom-[-120px] xl:right-[-70px] xl:w-[380px]"
          />
        </div>
      </div>
      <div className="mt-5 xl:ml-[-230px]">
        <div className="container mx-auto">
          <div className="max-w-[1030px]">
            <TrustBox />
          </div>
        </div>
      </div>
      <div className="mt-1 mb-0 px-5 lg:mt-20">
        <div className="container mx-auto">
          <div className="my-10 flex flex-col items-center gap-5">
            <div className="mb-1 w-auto max-w-[1100px] text-center font-interbold text-[26px] leading-tight text-[#0C2632] lg:mb-3 lg:text-[64px]">
              Gör vår kostnadsfria behovsanalys – snabbt, tryggt och enkelt
            </div>
            <div className="max-w-4xl text-center text-[13px] text-[#0C2632] lg:text-[22px]">
              Det fungerar ungefär som en sms-konversation där dina svar blir
              till grund för ditt skräddarsydda paket. Paketet innehåller en
              kombination av rekommenderade juridiska avtal samt råd om sparande
              och försäkringar du behöver. Du får även tillgång till det
              digitala kassaskåpet var ifrån du färdigställer dina juridiska
              avtal och bokar dina rekommenderade möten.
            </div>
            <div className="max-w-4xl text-center font-intermedium text-[13px] italic text-[#0C2632] lg:text-[22px]">
              Det tar runt 5 minuter att slutföra behovsanalysen och du
              förbinder dig inte till något.
            </div>
          </div>
        </div>
      </div>
      <ConversationEmbedded showModalOverride={showConversationModal} />
      <div className="px-5">
        <div className="container mx-auto">
          <div className="mx-auto my-5 max-w-[1300px] bg-quote bg-[length:100px_83px] bg-[top_left] bg-no-repeat pt-[48px] text-center font-interlight text-[24px] italic lg:my-14 lg:bg-[length:156px_130px] lg:pl-4 lg:pt-[74px] lg:text-[34px]">
            Juridiska avtal, sparande och försäkringar kan upplevas som
            krångligt och jobbigt. Tillsammans ska vi få det att kännas precis
            tvärtom – enkelt och ångestfritt. Att ta tag i de här frågorna
            kommer inte bara att ge dig lugn och ro här och nu, det kommer även
            underlätta framtiden för dig och dina närmaste.
          </div>
        </div>
      </div>
      <div className="mb-10 px-5">
        <div className="container mx-auto">
          <TutorialVideo />
          <div className="mt-2 mb-10 text-center font-intermedium text-[11px] italic lg:mb-24 lg:text-left lg:text-[22px]">
            Se filmen hur du går till väga för att skapa lugn och ro.
          </div>
          <div className="mx-auto mb-1 max-w-[1240px] text-center font-interbold text-[26px] leading-tight text-[#0C2632] lg:mb-3 lg:text-[54px]">
            Fenix Family – det enkla sättet att reda ut svåra saker som du borde
            tagit tag i för länge sen
          </div>
        </div>
      </div>
      <PartnerStepsSection />
      <div className="my-14 px-5 lg:my-28">
        <TestimonialSection
          buttonOnClick={() => setShowConversationModal(true)}
        />
      </div>
      <div className="mb-10 px-5">
        <div className="container mx-auto">
          <div className="flex flex-col items-center gap-5">
            <div>
              <svg
                width="94"
                height="94"
                viewBox="0 0 94 94"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M63.5039 16.5658C58.9095 17.1471 53.9662 20.0047 49.9992 26.4268C49.357 27.4665 48.2222 28.0993 47.0002 28.0993C45.7782 28.0993 44.6434 27.4665 44.0012 26.4269C40.0342 20.0049 35.0909 17.1473 30.4965 16.5659C25.8482 15.9777 21.0685 17.6526 17.3181 21.2336C9.89259 28.3894 9.89423 40.4497 17.323 47.6033L47.0002 76.1812L76.6774 47.6033C84.1061 40.4497 84.1078 28.3894 76.6824 21.2336C72.9319 17.6526 68.1522 15.9776 63.5039 16.5658ZM62.6188 9.57153C69.5944 8.68882 76.4385 11.2498 81.5576 16.1411L81.5676 16.1505C91.8778 26.0789 91.8778 42.7533 81.5675 52.6816L49.4453 83.614C48.0802 84.9285 45.9202 84.9285 44.5551 83.614L12.4329 52.6816C2.12264 42.7533 2.12264 26.0789 12.4329 16.1506L12.4427 16.1411L12.4427 16.1411C17.5619 11.2498 24.406 8.68892 31.3816 9.57168C37.0682 10.2913 42.5266 13.256 47.0002 18.5624C51.4738 13.2558 56.9322 10.2911 62.6188 9.57153Z"
                  fill="#0C2632"
                />
              </svg>
            </div>
            <div className="font-interbold text-[26px] text-dark-blue lg:text-[54px]">
              Vi är med dig hela vägen
            </div>
            <div className="mx-auto max-w-[900px] text-center text-[13px] text-dark-blue lg:text-[22px]">
              Fenix Family´s nya tjänst hjälper dig att{" "}
              <span className="font-intermedium underline">enkelt</span> ta fram
              och färdigställa de juridiska avtal samt råd om sparande och
              försäkringar, du behöver för att skapa lugn och ro...
            </div>
            <div className="mt-4 lg:mt-6">
              <ContactUs theme="dark" />
            </div>
            <div className="my-4 flex w-full flex-col items-center justify-evenly gap-3 lg:my-12 lg:ml-6 lg:flex-row lg:items-start">
              {[
                "juridiska avtal",
                "försäkringar",
                "allt inom sparande",
                "digitalt kassaskåp ",
              ].map((el) => {
                let iconWidth = 28;
                if (!isDesktopView()) {
                  iconWidth = 20;
                }
                return (
                  <div
                    key={el.length}
                    className="flex flex-row items-center gap-2 lg:gap-3"
                  >
                    <span className="font-intermedium text-[13px] uppercase tracking-[0.18em] lg:text-[18px]">
                      {el}
                    </span>
                    <div>{getCheckmarkIcon("#51B380", iconWidth, "100%")}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <AboutUs />
    </>
  );
}
