import { useLocation } from "react-router-dom";

export default function NoPage() {
  const location = useLocation();

  return (
    <div className="text-center text-dark-blue">
      <div className="mb-3 font-intermedium text-[20px]">
        Sidan hittades inte
      </div>
      <div className="font-interlight">
        {window.location.href.toString()}
        {/*{location.pathname}*/}
      </div>
    </div>
  );
}
