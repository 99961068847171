import * as store from "store";
import cookieStorage from "store/storages/cookieStorage";

export const userKey = "fnxUser";
export const conversationKey = "fnxConv";
export const partnerKey = "fnxPrt";
export const frPkgKey = "f6Or4S1";
export const homeRedirectKey = "fnxHmRd";
export const userEmailKey = "fnxUserEmail";

export function getFromStorage<T>(key: string, optionalDefaultValue?: T) {
  return store.get(key, optionalDefaultValue) as T | undefined;
}

export function setInStorage<T>(key: string, data: T) {
  return store.set(key, data) as T;
}

export function setCookie(key: string, data: string, expDays = 1) {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = key + "=" + data + "; " + expires + "; path=/";
}

export function getCookie(key: string) {
  return cookieStorage.read(key);
}

export function removeFromStorage(key: string) {
  return store.remove(key);
}
