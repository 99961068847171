import React, { useState } from "react";

import { getArrowIcon, getCloseIcon } from "../services/icons";

export default function ProfileSection({
  title,
  subtitle,
  icon,
  folded = true,
  children,
  handleOnClick,
  handleOnClose,
}) {
  const [isFolded, setFolded] = useState(folded);

  const changeFold = () => {
    if (!children) {
      return;
    }
    setFolded(!isFolded);
  };

  let headerClickHandle = changeFold;
  if (handleOnClick) {
    headerClickHandle = handleOnClick;
  }
  if (handleOnClose) {
    headerClickHandle = null;
  }

  let iconRotation = "180";
  if (isFolded && children) {
    iconRotation = "0";
  } else if (isFolded && !children) {
    iconRotation = "-90";
  }

  return (
    <div className="rounded-[8px] border-[0.5px] border-[#CECABA] bg-white p-3 lg:p-7">
      <div
        onClick={headerClickHandle}
        className={`flex flex-row items-center justify-start gap-2 ${
          headerClickHandle && "cursor-pointer"
        }`}
      >
        <div className="flex grow flex-row gap-3 lg:gap-5">
          {icon}
          <div>
            <div className="font-intermedium">{title}</div>
            <div className="text-[11px] text-[#CECABA]">{subtitle}</div>
          </div>
        </div>
        {handleOnClose ? (
          <div className="cursor-pointer" onClick={handleOnClose}>
            {getCloseIcon()}
          </div>
        ) : (
          <div
            className="transition-all"
            style={{ transform: `rotate(${iconRotation}deg)` }}
          >
            {getArrowIcon("#0C2632", "20", "100%")}
          </div>
        )}
      </div>
      {!isFolded && (
        <div className="pb-5 lg:pr-[32px] lg:pb-[20px] lg:pl-[42px]">
          <div className="mt-2 border-t-[0.5px] border-[#CECABA] pt-3 lg:mt-6 lg:pt-6">
            {children}
          </div>
        </div>
      )}
    </div>
  );
}
