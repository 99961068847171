import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";

const ChatContext = createContext(
  null as React.RefObject<HTMLIFrameElement> | null
);

export const ChatProvider = ({ children }: PropsWithChildren) => {
  const elRef = useRef<HTMLIFrameElement | null>(null);
  const sRef = useRef<HTMLScriptElement | null>(null);

  if (!elRef.current) {
    elRef.current = document.createElement("iframe");
    elRef.current.src =
      "https://62323.tctm.co/form/FRT472ABB2C5B9B141A3122D79446B1592288E1C3B7B9CB043B22F1905663C0A14D.html";
    elRef.current.className = "ctm-call-widget chat-widget-hidden";
    elRef.current?.setAttribute("style", "display:none");
  }
  if (!sRef.current) {
    sRef.current = document.createElement("script");
    sRef.current.src = "https://62323.tctm.co/formreactor.js";
    sRef.current.defer = true;
    sRef.current.async = true;
  }

  useLayoutEffect(() => {
    if (!elRef.current || !sRef.current) {
      return;
    }

    document.body.appendChild(elRef.current);
    document.body.appendChild(sRef.current);

    return () => {
      if (elRef.current) {
        document.body.removeChild(elRef.current);
      }
      if (sRef.current) {
        document.body.removeChild(sRef.current);
      }
    };
  }, []);

  return <ChatContext.Provider value={elRef}>{children}</ChatContext.Provider>;
};

export function useChatContext() {
  return useContext(ChatContext);
}
