import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.parcel2";

import { isDesktopView } from "../services/utils";

export default function PdfPreview({ file }) {
  const [numPages, setNumPages] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      {file && (
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={!isDesktopView() ? window.innerWidth - 30 : undefined}
              className="mb-5 bg-green-50 drop-shadow-xl lg:mb-10"
            />
          ))}
        </Document>
      )}
    </>
  );
}
