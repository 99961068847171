import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

const pencilIcon = new URL("../../assets/images/pencil.svg", import.meta.url);
import { useContract } from "../../context/ContractContext";
import { QuestionItem } from "../../types/entities";

type QuestionSummaryProps = {
  isWidget?: boolean;
  getQuestionById: (id: string) => QuestionItem | false;
  renderTitleText: (title: string) => string;
  renderEditQuestion: (id?: string) => void;
};

export default function QuestionSummary({
  isWidget = false,
  getQuestionById,
  renderTitleText,
  renderEditQuestion,
  editQuestionId,
  setEditQuestionId,
}: QuestionSummaryProps) {
  const [questionIds, setQuestionIds] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerBottomRef = useRef<HTMLDivElement>(null);
  const { contractData, dispatch } = useContract();

  useEffect(() => {
    if (!contractData.answersLog) {
      return;
    }
    const questionIds = [];
    for (const [key, value] of Object.entries(contractData.answersLog)) {
      questionIds.push(key);
    }
    setQuestionIds(questionIds);
  }, [contractData.answersLog]);

  useLayoutEffect(() => {
    if (isWidget && containerRef.current) {
      // containerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      containerRef.current.scrollTop = containerRef.current?.offsetHeight;
    }
  });

  const textSize = isWidget ? "text-[13px] pb-3 lg:pb-5" : "pb-3 lg:pb-5";
  const contClasses = isWidget
    ? "gap-2 lg:gap-5 max-h-[400px] overflow-y-auto pr-2"
    : "gap-4 lg:gap-7";

  const idsLength = questionIds.length;
  return (
    <>
      {questionIds.length > 0 && isWidget && (
        <div className="mb-5 flex flex-row items-center justify-end gap-2 uppercase tracking-widest">
          <img src={pencilIcon.href} width={18} />
          <div>Dina Svar</div>
        </div>
      )}
      <div ref={containerRef} className={`${contClasses} flex flex-col`}>
        {questionIds.map((i, index) => {
          const question = getQuestionById(i);
          if (!question) return null;

          const answerFields = contractData.answersLog[i]?.fields;
          if (!answerFields) {
            return;
          }

          if (editQuestionId === question._id && !isWidget) {
            return renderEditQuestion();
          }

          return (
            <div
              key={question._id}
              className={`${textSize} flex flex-row items-center gap-1 ${
                idsLength !== index + 1 ? "border-b-[1px]" : ""
              }`}
            >
              <div className="flex-grow">
                <div className="mb-2 font-interbold leading-tight text-dark-blue">
                  {renderTitleText(question.label)}
                </div>
                {question.fields.map((f) => {
                  if (answerFields[f._id]) {
                    const val = answerFields[f._id].value;
                    return (
                      <p
                        key={f._id}
                        className="font-interlight italic leading-tight text-[#003343]"
                      >
                        <span>{renderTitleText(f.label)}</span>
                        <span>
                          {question.type !== "singleAnswer" ? ` ${val}` : ""}
                        </span>
                      </p>
                    );
                  }
                })}
              </div>
              {/*<div*/}
              {/*  className="cursor-pointer text-[11px] underline"*/}
              {/*  onClick={() => {*/}
              {/*    setEditQuestionId(editQuestionId ? undefined : i);*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Ändra*/}
              {/*</div>*/}
            </div>
          );
        })}
        <div ref={containerBottomRef} />
      </div>
    </>
  );
}
