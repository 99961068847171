import {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";

import { ContractAction, ContractState } from "../types/contract";

const initialContract: ContractState = {
  hierarchy: null,
  questions: null,
  answersLog: {},
  currentQuestion: null,
  currentDepth: 0,
  questionTree: null,
  restorePrevState: false,
  finished: false,
};

const ContractContext = createContext(initialContract);

const contractReducer = (state: ContractState, action: ContractAction) => {
  switch (action.type) {
    case "remove-prev-state": {
      return {
        ...state,
        restorePrevState: false,
      };
    }
    case "set-data": {
      return {
        ...state,
        questionTree: action.payload.questionTree,
        questions: action.payload.questions,
        hierarchy: action.payload.hierarchy,
      };
    }
    case "question-tree": {
      return {
        ...state,
        questionTree: action.payload,
      };
    }
    case "current-question": {
      return {
        ...state,
        currentQuestion: action.payload,
      };
    }
    case "current-depth": {
      return {
        ...state,
        currentDepth: action.payload,
      };
    }
    case "answers-log": {
      return {
        ...state,
        answersLog: action.payload,
      };
    }
    case "set-prev-state": {
      return {
        ...state,
        ...action.payload,
        finished: false,
      };
    }
    case "set-finished": {
      return {
        ...state,
        finished: action.payload,
      };
    }
    default: {
      return state;
    }
  }
  return state;
};

export const ContractProvider = ({ children }: PropsWithChildren) => {
  const [contractData, dispatch] = useReducer(contractReducer, initialContract);
  return (
    <ContractContext.Provider value={{ contractData, dispatch }}>
      {children}
    </ContractContext.Provider>
  );
};

export function useContract() {
  return useContext(ContractContext);
}
