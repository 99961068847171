import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUserContext } from "../context/UserContext";
import { sendCmpEvent } from "../services/tracking";
import Button from "./Button";
import LoaderIcon from "./LoaderIcon";

export default function CheckoutForm({ cs, setPageData, price }) {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setSuccessful] = useState(false);
  const [fetchingStatus, setFetchingStatus] = useState(true);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (clientSecret) {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }) => {
          switch (paymentIntent?.status) {
            case "succeeded":
              setSuccessful(true);
              setMessage("payment-status-success");
              break;
            case "processing":
              setMessage("payment-status-processing");
              break;
            case "requires_payment_method":
              setMessage("payment-status-try-again");
              break;
            default:
              setMessage("payment-status-unknown");
              break;
          }
        })
        .catch((error: Error) => {
          console.log(error.message);
        })
        .finally(() => {
          setFetchingStatus(false);
        });
    } else {
      stripe
        .retrievePaymentIntent(cs)
        .then(({ paymentIntent }) => {
          if (paymentIntent?.status === "succeeded") {
            setMessage("payment-status-already-succeeded");
            setSuccessful(true);
          }
        })
        .catch((error: Error) => {
          console.log(error.message);
        })
        .finally(() => {
          setFetchingStatus(false);
        });
    }
  }, [stripe]);

  useEffect(() => {
    if (!isSuccessful || !user.accessToken) {
      return;
    }

    if (user.profile) {
      void sendCmpEvent("Mail_5", user.profile.email);
    }

    setPageData((prevState) => {
      const title = `Tack för din betalning ${user.info?.firstName}, kvittot kommer i mailen!`;
      return {
        ...prevState,
        title: title,
        subtitle: "",
      };
    });
  }, [isSuccessful]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href,
      },
      // redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (response.error && response.error.message) {
      setMessage(response.error.message);
    }

    setIsLoading(false);
  };

  const getPayButtonText = () => {
    return (
      <span id="button-text">
        {isLoading ? (
          <div className="flex w-full justify-center">
            <LoaderIcon size="w-6 h-6" />
          </div>
        ) : (
          "Betala"
        )}
      </span>
    );
  };

  if (isSuccessful) {
    return (
      <div className="mx-auto w-full lg:max-w-3xl">
        <div>
          <div className="mb-2 font-intermedium text-[22px] text-[#CFCAB8] lg:text-[24px]">
            Lugn&Ro-paket {price}:-
          </div>
          <ul className="list-outside list-disc pl-4 font-interlight">
            <li className="pb-2">
              Tillgång till hela vårt digitala avtalsutbud, till exempel
              samboavtal, äktenskapsförord, testamente och framtidsfullmakt.
            </li>
            <li className="pb-2">
              Trygg förvaring av alla dina viktiga avtal och dokument i ditt
              digitala kassaskåp.
            </li>
            <li className="pb-2">
              Ditt testamente skickas till dig för påskrift samt förvaras och
              övervakas därefter tryggt hos oss.
            </li>
            <li className="pb-2">
              Vägledning och erbjudanden inom sparande, pension och försäkringar
              baserat på våra digitala rekommendationer.
            </li>
            <li className="pb-2">
              12 månaders medlemskap (värde 449:-) ger dig tillgång till allt
              ovan.
            </li>
          </ul>
        </div>
        <div className="mt-5 lg:mt-10">
          <Button
            id="payment-success-to-vault"
            onClick={() => {
              if (user.profile) {
                void sendCmpEvent("Mail_6", user.profile.email);
              }
              navigate("/vault");
            }}
            title="TILL DITT KASSASKÅP"
            cssClasses="mx-auto"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {fetchingStatus ? (
        <div className="mx-auto flex justify-center">
          <LoaderIcon size="w-6 h-6" />
        </div>
      ) : (
        <>
          <form
            id="payment-form"
            onSubmit={(e) => {
              void handleSubmit(e);
            }}
          >
            <PaymentElement id="payment-element" />
            <div className="mb-6 flex flex-row items-start justify-between">
              <div>
                <div className="font-intermedium text-[20px] uppercase leading-none tracking-[0.09em] lg:text-[22px]">
                  totalbelopp
                </div>
                <div className="font-intermedium text-[#CFCAB8] lg:text-[18px]">
                  inkl. moms
                </div>
              </div>

              <div className="leading-none">
                <span className="font-intermedium text-[20px] uppercase underline lg:text-[22px]">
                  {price}
                </span>{" "}
                kr
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                onClick={() => {
                  if (user.profile) {
                    void sendCmpEvent("Mail_4_2", user.profile.email);
                  }
                }}
                title=""
                id="pay-for-package"
                disabled={isLoading || !stripe || !elements}
                type="submit"
              >
                {getPayButtonText()}
              </Button>
            </div>
            {message !== "" && <div id="payment-message">{message}</div>}
          </form>
        </>
      )}
    </>
  );
}
