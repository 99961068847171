import React from "react";
import { Link, Outlet } from "react-router-dom";

import Head from "./Head";

export default function Layout() {
  return (
    <main
      className="relative flex flex-grow flex-col bg-[#FFFEFB] bg-main bg-[center_top] bg-no-repeat"
      style={{ backgroundSize: "100% auto" }}
    >
      <div className="absolute top-0 h-[80px] w-full bg-gradient-to-b from-[#0000008a] to-transparent lg:h-[120px]"></div>
      <div className="relative flex flex-grow flex-col px-5">
        <div className="container mx-auto">
          <Head />
        </div>
        <div
          className="container relative mx-auto flex flex-grow flex-col rounded-lg bg-[#FFFEFB] px-3 py-4 lg:p-10"
          style={{ boxShadow: "rgba(0,0,0,.075) 0px 4px 10px 4px" }}
        >
          <Outlet />
        </div>
        <div className="text-dark-blue">
          <div className="container mx-auto flex items-center justify-center gap-5 py-5">
            <div className="text-center font-interlight">
              © Fenix Family AB 2023
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
