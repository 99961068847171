import React from "react";

import { useUserContext } from "../../context/UserContext";

export default function UserInfo() {
  const { user } = useUserContext();
  return (
    <div className="mb-5 border-b-[1px] border-[#E7E4D9] pb-3 text-[#CFCAB8]">
      <div className="mb-2 font-intermedium text-[18px] uppercase leading-none tracking-widest lg:text-[24px]">
        {user.info?.firstName} {user.info?.lastName}
      </div>
      <div>{user.info?.personNumber}</div>
    </div>
  );
}
