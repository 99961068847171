import React, { useEffect, useRef, useState } from "react";

import AboutUs from "../components/AboutUs";
import Button from "../components/Button";
import ContactUs from "../components/ContactUs";
import ConversationEmbedded from "../components/ConversationEmbedded";
import Head from "../components/Head";
import ServicesSection from "../components/ServicesSection";
import TestimonialSection from "../components/TestimonialSection";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { getDownArrowIcon } from "../services/icons";
import { homeRedirectKey, setInStorage } from "../services/storage";

const videoMain = new URL("../assets/video/main.mp4", import.meta.url);
const videoMainThumb = new URL("../assets/video/main.jpg", import.meta.url);

const steps = [
  {
    icon: new URL("../assets/images/star.svg", import.meta.url),
    title:
      "1. Klicka på knappen nedan och svara på några frågor om din livssituation och dina önskemål.",
  },
  {
    icon: new URL("../assets/images/bankid-dark.svg", import.meta.url),
    title:
      "2. Logga in med Mobilt BankID för att ta del av vår rekommendation och planera din livssituation tillsammans med oss och våra partners.",
  },
  {
    icon: new URL("../assets/images/file-check.svg", import.meta.url),
    title:
      "3. Färdigställ dina juridiska avtal, boka möte för rådgivning kring försäkringar och sparande - luta dig tillbaka och andas ut.",
  },
];

export default function Index() {
  const [showConversationModal, setShowConversationModal] = useState(false);
  const refVideo = useRef();

  useEffect(() => {
    setInStorage(homeRedirectKey, window.location.pathname);
  }, []);

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden">
        <div className="absolute z-20 h-full w-full">
          <div className="h-full px-5">
            <div className="container mx-auto h-full">
              <div className="flex h-full flex-col items-center justify-between">
                <Head homepage={true} topLinks={true} />
                <div className="flex grow flex-col justify-center">
                  <div className="mb-10 max-w-[700px] text-center font-interbold text-[32px] italic leading-tight text-[#FFFEFB] drop-shadow-xl lg:text-[64px]">
                    Planera för imorgon - så att du kan leva idag
                  </div>
                  {/*<div className="mx-auto">*/}
                  {/*  <BankIdLoginButton id="login-index" title="börja här" />*/}
                  {/*</div>*/}
                </div>
                <div className="mb-[20px] flex flex-row uppercase tracking-[0.1em] text-[#FFFEFB] lg:mb-[40px]">
                  <div className="text-center">
                    välkommen till <br />
                    fenix family
                  </div>
                  <div className="self-end">{getDownArrowIcon()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-0 z-10 h-[80px] w-full bg-gradient-to-b from-[#0000008a] to-transparent lg:h-[120px]"></div>
        <video
          ref={refVideo}
          autoPlay
          playsInline
          muted
          loop
          src={videoMain.href}
          className="h-full min-h-[420px] w-screen object-cover"
          style={{ transform: "scale(1.0)" }}
          // onLoadedData={onLoadedVideo}
          preload="auto"
          poster={videoMainThumb.href}
        />
      </div>
      <div className="bg-[#FFFEFB] px-5">
        <div className="container mx-auto">
          <div className="my-10 lg:my-32 lg:px-20">
            <div className="mb-3 font-interbold text-[28px] italic leading-tight text-[#0C2632] lg:text-[48px]">
              Juridik, sparande och försäkringar i den kontext som är ditt liv -
              här, nu och i framtiden.
            </div>
            <div className="max-w-3xl font-interlight text-[#0C2632]">
              Juridik, avtal och testamenten kan upplevas som krångligt, kanske
              till och med lite obehagligt. Tillsammans ska vi få det att kännas
              precis tvärtom, nämligen enkelt och ångestfritt. Att ta tag i de
              här frågorna kommer inte bara att ge dig lugn och ro här och nu,
              det kommer även underlätta framtiden för dina närmaste.{" "}
              <br className="lg:hidden" />
              <span className="font-intermedium">
                Läs mer om vad vi kan hjälpa dig med
              </span>{" "}
              <div className="inline-block">
                <div className="relative top-[12px]">
                  {getDownArrowIcon("#0C2632")}
                </div>
              </div>
            </div>
          </div>
          <ServicesSection />
          <div className="mb-10 flex justify-center">
            <ContactUs />
          </div>
          <div className="my-10 lg:my-20 lg:px-20">
            <div className="mb-3 max-w-4xl font-interbold text-[28px] italic leading-tight text-[#0C2632] lg:text-[48px]">
              Ett snabbt, tryggt och enkelt sätt att reda ut svåra saker.
            </div>
            <div className="max-w-3xl max-w-4xl font-interlight text-[#0C2632]">
              För att kunna ge så bra rekommendationer som möjligt behöver vi
              lära känna varandra lite. Börja med att svara på några frågor om
              din livssituation och dina önskemål. Det tar inte mer än 5 minuter
              och du förbinder dig inte till något. Bra att veta är också att du
              kan ändra de avtal vi skapar tillsammans ifall din livssituation
              förändras, när och hur ofta du vill. <br className="lg:hidden" />
              <span className="font-intermedium">Gör så här</span>{" "}
              <div className="inline-block">
                <div className="relative top-[12px]">
                  {getDownArrowIcon("#0C2632")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="py-10 lg:py-20"
        style={{ background: "rgba(232, 227, 210, 0.5)" }}
      >
        <div className="container mx-auto px-5 text-center">
          <div className="mb-10 flex flex-col items-center justify-around gap-10 lg:mb-20 lg:flex-row lg:items-start">
            {steps.map((step) => (
              <div
                key={step.title}
                className="flex shrink-0 grow basis-0 flex-col items-center justify-center gap-5"
              >
                <div className="flex h-[120px] w-[120px] items-center justify-center rounded-full bg-[#CECABA] lg:h-[150px] lg:w-[150px]">
                  <img src={step.icon} className="w-[50px]" />
                </div>
                <div className="text-center font-intermedium text-[18px] text-dark-blue">
                  {step.title}
                </div>
              </div>
            ))}
          </div>
          <div className="inline-block">
            <Button
              onClick={() => setShowConversationModal(true)}
              title="börja här"
              id="conv-start-home"
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto bg-[#FFFEFB] px-5">
        <div className="my-10 lg:my-32 lg:px-20">
          <div className="mb-3 max-w-3xl font-interbold text-[28px] italic leading-tight text-[#0C2632] lg:text-[48px]">
            Robert, 55 pappa till Cordelia, 21 och Leonbergern “Maui”
          </div>
          <div className="max-w-3xl font-interlight text-[#0C2632]">
            Planerade sin nutid och framtiden för sina närmaste tillsammans med
            oss på Fenix Family. Se hela klippet med Robert i spelaren nedan:
          </div>
        </div>
        <div className="mb-10 lg:mb-24">
          <TestimonialSection
            buttonOnClick={() => setShowConversationModal(true)}
          />
        </div>
      </div>
      <AboutUs />
      <ConversationEmbedded
        showInline={false}
        showModalOverride={showConversationModal}
      />
    </ServiceSectionProvider>
  );
}
