import React, { useEffect, useState } from "react";

import { useUserContext } from "../context/UserContext";
import { isDesktopView } from "../services/utils";
import Modal from "./Modal";

const advisorImg = new URL("../assets/images/advisor.png", import.meta.url);

export default function ContactAdvisor({ iconPosition = "left" }) {
  const [bookModal, setBookModal] = useState(false);
  const [modalWidth, setModalWidth] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);
  const { user } = useUserContext();

  const rowClasses = iconPosition === "right" ? "flex-row-reverse" : "flex-row";
  const textClasses = iconPosition === "right" ? "text-right" : "";

  useEffect(() => {
    const w = window.innerWidth;
    const h = window.innerHeight;

    const width = Math.round(parseInt(w, 10));
    const iframeWidth = width <= 768 ? width : 768;
    const k = width <= 768 ? 1 : 0.8;

    setModalWidth(iframeWidth);
    setModalHeight(Math.round(parseInt(h, 10) * k));
  }, []);

  const getAcuityUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const data = {
      owner: 18650906,
      calendarID: 8015164,
    };
    // let source = "Livsplanering";
    // if (partner) {
    //   source = `${source} / ${partner.title}`;
    // }
    // data["field:7457489"] = source;

    if (urlParams.has("utm_source")) {
      data["field:12566787"] = urlParams.get("utm_source");
    }
    if (urlParams.has("utm_medium")) {
      data["field:12566788"] = urlParams.get("utm_medium");
    }
    if (urlParams.has("utm_campaign")) {
      data["field:12566789"] = urlParams.get("utm_campaign");
    }
    if (urlParams.has("utm_content")) {
      data["field:12566791"] = urlParams.get("utm_content");
    }
    if (urlParams.has("utm_term")) {
      data["field:12566790"] = urlParams.get("utm_term");
    }

    const params = new URLSearchParams(data);
    return `https://app.acuityscheduling.com/schedule.php?${params.toString()}`;
  };
  const onModalClose = () => {
    setBookModal(false);
  };

  if (!user.info) {
    return null;
  }
  return (
    <>
      <div
        className={`flex cursor-pointer items-center gap-3 ${rowClasses}`}
        onClick={() => setBookModal(!bookModal)}
      >
        <div className="relative">
          <img src={advisorImg.href} width={40} />
          <div className="absolute right-0 bottom-0 z-10 h-[12px] w-[12px] rounded-full border-[2px] border-white bg-[#7DDE8D]"></div>
        </div>
        <div className="hidden lg:block">
          <div
            className={`mb-0 font-intermedium text-[14px] underline ${textClasses}`}
          >
            Hej {user.info.firstName}
          </div>
          <div
            className={`font-interlight text-[12px] italic opacity-60 ${textClasses}`}
          >
            Boka möte med mig här
          </div>
        </div>
      </div>
      {bookModal && (
        <Modal onClose={onModalClose}>
          <iframe
            src={getAcuityUrl()}
            width={modalWidth}
            height={modalHeight}
            className={`mx-auto max-h-full max-w-full ${
              !isDesktopView() ? "bg-white p-3" : ""
            }`}
          />
        </Modal>
      )}
    </>
  );
}
