type PartnerType = {
  title: string;
  url: string;
  pkg?: number;
  logo?: URL;
};

export const partners: PartnerType[] = [
  {
    title: "Bostadsratterna",
    url: "bostadsratterna",
    logo: new URL(
      "../assets/images/bostadsratterna_logotyp_cmyk.png",
      import.meta.url
    ),
  },
  {
    title: "SocialMedia",
    url: "social-media",
  },
  {
    title: "Fenix society",
    url: "fenix-society",
  },
  {
    title: "Fenix family",
    url: "fenixfamily",
    pkg: 99,
  },
  {
    title: "ActiveCampaign",
    url: "active-campaign",
    pkg: 101,
  },
];

export function getPartnerByUrl(url: string | null) {
  if (url === null) {
    return null;
  }

  const partnerExist = partners.find((p) => url === p.url);
  return partnerExist ? partnerExist : null;
}
