import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Recommendation from "../components/conversation/Recommendation";
import { useChatContext } from "../context/ChatContext";
import {
  useConvContext,
  useConvDispatchContext,
} from "../context/ConversationContext";
import { RequireUserAuth, useUserContext } from "../context/UserContext";

function RecommendationPage() {
  const [loading, setLoading] = useState(true);
  const { conversation } = useConvContext();
  const navigate = useNavigate();
  const chat = useChatContext();
  const dispatch = useConvDispatchContext();
  const { user } = useUserContext();

  useEffect(() => {
    if (!conversation || conversation.values.length === 0) {
      navigate("/vault");
      return;
    }
    setLoading(false);

    if (chat?.current) {
      chat.current.classList.add("chat-widget-hidden");
    }
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Recommendation
      onFinish={() => {
        dispatch({
          type: "set-status",
          payload: { status: "packet", accessToken: user.accessToken },
        });
        navigate("/conversation");
      }}
    />
  );
}

export default function RecommendationPageWrapper() {
  return (
    <RequireUserAuth>
      <RecommendationPage />
    </RequireUserAuth>
  );
}
