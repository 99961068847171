import parse from "html-react-parser";
import React from "react";

import { generateRandomString } from "../../services/utils";
import {
  AnswerData,
  AnswersLogItem,
  QuestionItem,
  VariablesList,
} from "../../types/entities";
import InputFields from "./fields/InputFields";
import Radio from "./fields/Radio";

type Props = {
  question: QuestionItem;
  submitQuestionData?: (a: AnswerData[]) => void;
  setVariables?: React.Dispatch<React.SetStateAction<VariablesList>>;
  variables: VariablesList;
  renderTitleText: (t: string) => string;
  editMode?: boolean;
  values?: AnswersLogItem;
  updateQuestionData?: (a: AnswerData[], id: string) => void;
};

export default function Question({
  question,
  submitQuestionData,
  setVariables,
  variables,
  renderTitleText,
  editMode,
  values,
  updateQuestionData,
}: Props) {
  const onNextClick = (data: AnswerData[]) => {
    if (editMode && updateQuestionData) {
      updateQuestionData(data, question._id);
      return;
    }
    if (submitQuestionData) {
      submitQuestionData(data);
    }
  };

  const checkForRange = () => {
    const res = question.fields.filter((el) => {
      return el.format === "percentage";
    });
    return res.length >= 1;
  };

  const renderFields = () => {
    const type = question.type;
    if (type === "singleAnswer") {
      return (
        <Radio
          fields={question.fields}
          onSubmit={onNextClick}
          key={generateRandomString()}
          renderTitleText={renderTitleText}
          values={values}
          editMode={editMode}
        />
      );
    }
    if (type === "freeText") {
      return (
        <InputFields
          fields={question.fields}
          onSubmit={onNextClick}
          key={generateRandomString()}
          variables={variables}
          setVariables={setVariables}
          renderTitleText={renderTitleText}
          values={values}
          editMode={editMode}
        />
      );
    }

    return null;
  };

  return (
    <div className="mx-auto lg:max-w-xl">
      <h1 className="mb-3 font-intermedium text-[18px] leading-tight text-dark-blue lg:mb-6 lg:text-[24px]">
        {renderTitleText(question.label)}
      </h1>
      <div>
        {question.instructions.length > 0 && !checkForRange() ? (
          <div className="mb-4 font-interlight text-[14px] italic lg:mb-8">
            {parse(renderTitleText(question.instructions))}
          </div>
        ) : null}

        {renderFields()}
      </div>
    </div>
  );
}
