import React, { useEffect, useState } from "react";

import { useUserContext } from "../context/UserContext";
import { updateProfileInfo } from "../services/ida";
import { isFieldValid } from "../services/utils";
import { ErrorFields } from "../types/entities";
import Button from "./Button";
import ErrorFormMessage from "./contract/ErrorFormMessage";
import LoaderIcon from "./LoaderIcon";

const formGroup = (
  label,
  name,
  value,
  onChangeHandle,
  type = "text",
  disabled = false
) => {
  return (
    <div>
      <label
        className="mb-1 block font-intermedium lg:text-[18px]"
        htmlFor={name}
      >
        {label}
      </label>
      <input
        type={type}
        value={value}
        name={name}
        className="w-full rounded-[3px] border-[1px] border-[#CECABA] px-2 py-2 lg:py-3 lg:px-5"
        onChange={onChangeHandle}
        disabled={disabled}
      />
    </div>
  );
};

export default function ProfileForm({ onSuccessHandler }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [errorFields, setErrorFields] = useState({} as ErrorFields);
  const { user } = useUserContext();

  useEffect(() => {
    if (!user.profile) {
      return;
    }
    setAddress(user.profile.address.address ?? "");
    setPostcode(user.profile.address.postalCode ?? "");
    setCity(user.profile.address.city ?? "");
    setPhone(user.profile.phoneNumber ?? "");
    setEmail(user.profile.email ?? "");
  }, [user.profile]);

  const formSubmit = () => {
    const errors = validateData();
    if (Object.entries(errors).length > 0) {
      return;
    }

    const data = {
      ...user.profile,
      address: {
        ...user.profile?.address,
        address: address,
        postalCode: postcode,
        city: city,
      },
      phoneNumber: phone,
      email: email,
    };

    setLoading(true);
    setMessage(undefined);
    void updateProfileInfo(user.accessToken, data)
      .then(() => {
        setMessage("Sparat");
        void onSuccessHandler();
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const validateData = () => {
    const errors = {} as ErrorFields;

    errors["email"] = isFieldValid(email, "email");
    errors["phone"] = isFieldValid(phone, "phone");
    errors["postcode"] = isFieldValid(postcode, "postcode");

    const resOut = {} as ErrorFields;
    for (const [k, v] of Object.entries({ ...errorFields, ...errors })) {
      if (v !== true) {
        resOut[k] = v;
      }
    }
    setErrorFields(resOut);
    return resOut;
  };

  const removeError = (key: string) => {
    const errors = { ...errorFields };
    delete errors[key];
    setErrorFields(errors);
  };

  const getButtonText = () => {
    return (
      <span>
        {loading ? (
          <div className="flex w-full justify-center">
            <LoaderIcon size="w-5 h-5" />
          </div>
        ) : (
          "Klar"
        )}
      </span>
    );
  };

  return (
    <div className="">
      <div className="mb-4 font-intermedium">
        <div className="text-dark-blue lg:text-[18px]">Namn</div>
        <div className="text-[#CECABA] lg:text-[20px]">
          {user.info?.firstName} {user.info?.lastName}
        </div>
      </div>
      <div className="font-intermedium">
        <div className="text-dark-blue lg:text-[18px]">Personnummer</div>
        <div className="text-[#CECABA] lg:text-[20px]">
          {user.info?.personNumber}
        </div>
      </div>
      <hr className="my-4 border-b-[#CFCAB8] border-opacity-25 lg:my-8" />

      <form>
        <div className="mb-5 flex flex-col gap-3 lg:mb-10 lg:gap-6">
          <div className="flex flex-col gap-3 lg:flex-row lg:gap-6">
            <div className="grow">
              {formGroup("Adress", "address", address, (e) =>
                setAddress(e.target.value)
              )}
            </div>
            <div className="">
              {formGroup("Postnummer", "postcode", postcode, (e) => {
                setPostcode(e.target.value);
                removeError("postcode");
              })}
              {errorFields["postcode"] ? (
                <ErrorFormMessage message={errorFields["postcode"]} />
              ) : null}
            </div>
          </div>
          <div className="">
            {formGroup("Stad", "city", city, (e) => setCity(e.target.value))}
          </div>
          <div className="">
            {formGroup(
              "Telefonnummer",
              "phone",
              phone,
              (e) => {
                removeError("phone");
                setPhone(e.target.value);
              },
              "tel"
            )}
            {errorFields["phone"] ? (
              <ErrorFormMessage message={errorFields["phone"]} />
            ) : null}
          </div>
          <div className="">
            {formGroup(
              "Email",
              "email",
              email,
              (e) => {
                removeError("email");
                setEmail(e.target.value);
              },
              "email"
            )}
            {errorFields["email"] ? (
              <ErrorFormMessage message={errorFields["email"]} />
            ) : null}
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <div className="flex flex-row items-center gap-3">
            {message && (
              <div className="text-[14px] text-[#499d49]">{message}</div>
            )}
            <Button
              onClick={formSubmit}
              title={getButtonText()}
              rightArrow={!loading}
              // cssClasses="min-w-[200px]"s
            />
          </div>
        </div>
      </form>
    </div>
  );
}
