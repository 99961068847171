import React from "react";

import ErrorFormMessage from "../ErrorFormMessage";

type Props = {
  type: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value?: string | number;
  placeholder?: string;
  theme?: string;
  required?: boolean;
  readonly?: boolean;
  error?: React.ReactElement | boolean;
};

export default function Input({
  type,
  onChange,
  name,
  label,
  value,
  placeholder,
  theme,
  required,
  error,
  readonly,
}: Props) {
  let themeLabelClasses = "text-dark-blue";
  let themeClasses =
    "bg-white border-[1px] border-[#CFCAB8] placeholder:text-[#FFFFFF80] read-only:bg-[#f6f6f6] p-2 lg:p-3";

  if (theme === "transparent") {
    themeClasses = "bg-transparent border-white placeholder:text-[#FFFFFF80]";
    themeLabelClasses = "text-white";
  }

  if (error) {
    themeClasses += " border-red-500";
    themeLabelClasses += " text-red-500";
  }

  return (
    <>
      <label
        htmlFor={name}
        className={`${themeLabelClasses} mb-1 block lg:text-[18px]`}
      >
        {label} {required ? <span className="text-red-700">*</span> : ""}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        onChange={onChange}
        className={`${themeClasses} block w-full rounded-[8px] ring-inset focus:outline-none focus-visible:ring-1 focus-visible:ring-[#cfcab86e]`}
        placeholder={placeholder}
        value={value}
        required={required}
        readOnly={readonly}
      />
      {error ? <ErrorFormMessage message={error} /> : null}
    </>
  );
}
