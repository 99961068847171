import React, { PropsWithChildren } from "react";

import { getCloseIcon } from "../../services/icons";

export default function QuestionEditWrapper({
  children,
  onClose,
}: PropsWithChildren) {
  return (
    <div className="relative rounded-[16px] border-[1px] border-[#CFCAB8] bg-[#ffffff] p-5 lg:p-10">
      <div className="absolute right-4 top-4">
        <div className="cursor-pointer" onClick={onClose}>
          {getCloseIcon("#CFCAB8", "18", "100%")}
        </div>
      </div>
      {children}
    </div>
  );
}
