import "../assets/styles/stripe.scss";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import React, { useState } from "react";

import CheckoutForm from "./CheckoutForm";
import PageHead from "./PageHead";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(publishableKey);

export default function Checkout({
  clientSecret,
  price,
}: {
  clientSecret: string;
  price: string;
}) {
  const [pageData, setPageData] = useState({
    title: "Dags att betala",
    subtitle:
      "Få tillgång till vår avtalsgenerator, ditt digitala kassaskåp och mycket mer",
    icon: "chat",
    pagePadding: "",
  });
  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    appearance: {
      theme: "stripe",
      variables: { borderRadius: "3px", spacingUnit: "6px" },
      rules: {
        ".Input": {
          borderColor: "#CFCAB8",
          paddingRight: "15px",
          paddingLeft: "15px",
          paddingTop: "15px",
          paddingBottom: "15px",
        },
        ".Input::placeholder": {
          color: "#CFCAB8",
        },
        ".Label": {
          color: "#002733",
          fontWeight: "500",
          marginBottom: "10px",
        },
      },
    },
    locale: "sv", // sv, en
  };

  return (
    <div className={pageData.pagePadding}>
      <PageHead
        title={pageData.title}
        subtitle={pageData.subtitle}
        icon={pageData.icon}
      />
      <div className="mx-auto mt-5 w-full lg:mt-20 lg:max-w-4xl">
        <div className="checkout-form">
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                cs={clientSecret}
                setPageData={setPageData}
                price={price}
              />
            </Elements>
          )}
        </div>
      </div>
    </div>
  );
}
