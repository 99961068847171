import React, {
  createRef,
  Dispatch,
  forwardRef,
  PropsWithChildren,
  PropsWithRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import {
  useConvContext,
  useConvDispatchContext,
} from "../../context/ConversationContext";
import { getArrowIcon, getDownArrowIcon } from "../../services/icons";
import {
  inputValueMask,
  isDesktopView,
  isFieldValid,
} from "../../services/utils";
import { ConvItem, ConvOption } from "../../types/conversation";
import { ErrorFields } from "../../types/entities";
import ErrorFormMessage from "../contract/ErrorFormMessage";

const sendIcon = new URL("../../assets/images/send.svg", import.meta.url);

type ConversationItemProps = {
  item: ConvItem;
  embeddedMode: boolean;
  scrollContainerToBottom: () => void;
};

const delayDelta = 150;

const Item = forwardRef<HTMLInputElement, ConversationItemProps>(
  ({ item, scrollContainerToBottom, embeddedMode }, ref) => {
    const [answers, setAnswers] = useState<string[] | undefined>();
    const [finished, setFinished] = useState(false);
    const [msgInputHeight, setMsgInputHeight] = useState(1);
    const [errorFields, setErrorFields] = useState({} as ErrorFields);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const selectRef = useRef<HTMLSelectElement>(null);

    const { conversation } = useConvContext();
    const dispatch = useConvDispatchContext();

    useLayoutEffect(() => {
      scrollContainerToBottom();
      // console.log("scrollContainerToBottom");
      if (!embeddedMode && !isDesktopView()) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    }, []);

    useEffect(() => {
      const currentValue = conversation.values.find((el) => el.id === item.id);
      if (currentValue) {
        setAnswers(currentValue.value);
        setFinished(true);
      } else {
        if (typeof item === "object" && item.type === "line") {
          setTimeout(
            () => {
              updateAnswers("1");
            },
            item.delay ? item.delay : 800
          );
        }
      }
    }, []);

    useEffect(() => {
      if (!answers) {
        return;
      }

      if (!item.multiple) {
        finishItem();
      }
    }, [answers]);

    const updateAnswers = (val: string) => {
      if (finished) {
        return;
      }

      const errors = validateData(val);
      if (Object.entries(errors).length > 0) {
        console.log(errors);
        return;
      }

      if (answers === undefined) {
        setAnswers([val]);
        return;
      }

      const valueExists = answers.find((el) => el === val);
      if (valueExists) {
        setAnswers(answers.filter((v) => v !== val));
        return;
      }

      if (item.multiple) {
        setAnswers([...answers, val]);
      } else {
        setAnswers([val]);
      }
    };

    const validateData = (val) => {
      const errors = {} as ErrorFields;
      if (item.inputType === "email") {
        errors["email"] = isFieldValid(val, "email");
      }

      const resOut = {} as ErrorFields;
      for (const [k, v] of Object.entries({ ...errorFields, ...errors })) {
        if (v !== true) {
          resOut[k] = v;
        }
      }
      setErrorFields(resOut);
      return resOut;
    };

    const removeError = (key: string) => {
      const errors = { ...errorFields };
      delete errors[key];
      setErrorFields(errors);
    };

    const submitValue = () => {
      if (!answers) {
        return;
      }

      const obj = { id: item.id, name: item.name, value: answers };

      dispatch({ type: "set-values", payload: { answers: answers, obj: obj } });
    };

    const renderOptions = () => {
      if (!item.options || item.select) {
        return;
      }
      return (
        <div className="flex flex-col gap-1">
          {item.options.map((i, index) => renderOption(i, index))}
        </div>
      );
    };

    const renderOption = (i, index) => {
      const { value, label }: ConvOption = i;
      const selected = answers ? answers.includes(value) : false;
      if (finished && !selected) {
        return;
      }

      let contClasses = "";
      const baseClasses =
        "cursor-pointer inline-block rounded-[16px] border-[1px]";
      let styleClasses = "border-dark-blue bg-white text-dark-blue";
      let hoverClasses = "hover:opacity-80";
      if (selected && finished) {
        styleClasses =
          "bg-dark-blue border-dark-blue text-white cursor-default";
        hoverClasses = "";
        contClasses = "conv-tail-answer";
      }
      if (selected && item.multiple && !finished) {
        styleClasses = "border-dark-blue border-[2px] font-intermedium";
        hoverClasses = "";
      }
      return (
        <div
          key={value}
          className="items-conv max-w-[94%] self-end lg:max-w-[80%]"
          onClick={() => updateAnswers(value)}
          style={{ animationDelay: `${parseInt(index + 1) * delayDelta}ms` }}
        >
          <div className={contClasses}>
            <div
              id={`item-${item.id}-${value}`}
              className={`item-option ${baseClasses} ${styleClasses} ${hoverClasses} py-1.5 px-3 text-[14px] leading-tight transition`}
            >
              {label.charAt(0).toUpperCase() + label.slice(1)}
            </div>
          </div>
        </div>
      );
    };

    const renderConfirmationForMultiple = () => {
      if (finished || !item.multiple) {
        return;
      }

      let delay = 0;
      if (item.options?.length > 0) {
        delay += (item.options.length + 1) * delayDelta;
      }

      return (
        <>
          {renderQuestion("Är du nöjd med ditt svar?", delay)}
          <div
            className="items-conv self-end"
            onClick={() => {
              if (!answers || answers.length === 0) {
                return;
              }
              finishItem();
              submitValue();
            }}
          >
            <div
              id={`item-${item.id}-next`}
              className="item-next items-conv inline-block cursor-pointer rounded-[16px] border-[1px] border-dark-blue bg-white py-1.5 px-3 text-[14px] leading-tight text-dark-blue transition"
              style={{
                animationDelay: `${delay + delayDelta}ms`,
              }}
            >
              Ja, nästa fråga!
            </div>
          </div>
        </>
      );
    };

    const renderQuestion = (item: string | ConvItem, delay = 0) => {
      return (
        <div
          className="items-conv max-w-[94%] self-start lg:max-w-[80%]"
          style={{ animationDelay: `${delay}ms` }}
        >
          <div className="conv-tail-question">
            <div
              onClick={() => updateAnswers("1")}
              className="inline-block rounded-[16px] bg-[#E9E3D0] py-2 px-3 text-[14px] leading-tight text-dark-blue"
            >
              {typeof item === "string" && item}
              {typeof item === "object" && item.multiple && (
                <>
                  {item.title}
                  {!finished && (
                    <>
                      <br />
                      <div className="mt-1 text-[12px]">
                        Välj alla alternativ som stämmer.
                      </div>
                    </>
                  )}
                </>
              )}
              {typeof item === "object" && !item.multiple && item.title}
            </div>
          </div>
        </div>
      );
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      const regexp = RegExp("\\n", "g");
      const countLines = [...value.matchAll(regexp)].length;
      setMsgInputHeight(countLines + 1);
    };

    const renderInputOption = () => {
      if (!item.input) {
        return;
      }
      let inputOptionValue = answers ? [answers[0]] : [];
      if (item.options && answers) {
        inputOptionValue = answers.filter(
          (v) => !item.options?.find((o) => o.value === v)
        );
      }

      if (finished && inputOptionValue.length === 0) {
        return;
      }

      if (inputOptionValue && inputOptionValue.length > 0) {
        let classes = "border-[1px] bg-dark-blue text-white";
        if (!finished) {
          classes = "border-dark-blue border-[2px] font-intermedium";
        }
        return (
          <div
            className="items-conv relative self-end"
            style={{
              animationDelay: `${delayDelta}ms`,
            }}
          >
            <div className="conv-tail-answer">
              <div
                className={`inline-block rounded-[16px] py-1.5 px-3 text-[14px] leading-tight transition ${classes}`}
              >
                {inputOptionValue} {item.inputValueSuffix}
              </div>
            </div>
          </div>
        );
      }

      let delay = 0;
      if (item.options?.length > 0) {
        delay += (item.options.length + 1) * delayDelta;
      }

      return (
        <div
          className="items-conv shadow-default relative mt-0 mb-[22px] flex flex-row items-center rounded-[16px] border border-[#CFCAB8]"
          style={{
            animationDelay: `${delay + delayDelta}ms`,
          }}
        >
          <textarea
            id={`item-${item.id}`}
            className="item-input grow resize-none border-0 bg-transparent py-2 px-3 text-[14px] text-dark-blue outline-0 placeholder:text-[#CFCAB8] focus:outline-none"
            rows={msgInputHeight}
            onChange={(e) => {
              if (item.inputType === "numbers") {
                inputRef.current.value = inputValueMask(
                  e.currentTarget.value,
                  "numbers"
                );
              }
              if (item.inputType === "email") {
                removeError("email");
              }
              handleMessageChange(e);
            }}
            name="text"
            placeholder={item.inputPlaceholder}
            ref={inputRef}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                !e.shiftKey &&
                e.currentTarget.value !== ""
              ) {
                updateAnswers(e.currentTarget.value);
              }
            }}
          />
          <button
            type="button"
            className="p-2"
            onClick={() => {
              if (inputRef.current && inputRef.current.value !== "") {
                updateAnswers(inputRef.current.value);
              }
            }}
          >
            <img src={sendIcon.href} />
          </button>
          {errorFields["email"] ? (
            <div className="absolute bottom-[-24px] left-[10px]">
              <ErrorFormMessage message={errorFields["email"]} />
            </div>
          ) : null}
        </div>
      );
    };

    const renderSelectOption = () => {
      if (!item.select || !item.options) {
        return;
      }
      let selectValue = answers ? answers[0] : null;
      if (selectValue) {
        const opt = item.options.find((o) => o.value === selectValue);
        selectValue = opt ? opt.label : null;
      }

      if (finished && !selectValue) {
        return;
      }

      if (selectValue) {
        let classes = "border-[1px] bg-dark-blue text-white";
        if (!finished) {
          classes = "border-dark-blue border-[2px] font-intermedium";
        }
        return (
          <div
            className="items-conv relative self-end"
            style={{
              animationDelay: `${delayDelta}ms`,
            }}
          >
            <div className="conv-tail-answer">
              <div
                className={`inline-block rounded-[16px] py-1.5 px-3 text-[14px] leading-tight transition ${classes}`}
              >
                {selectValue.charAt(0).toUpperCase() + selectValue.slice(1)}
              </div>
            </div>
          </div>
        );
      }

      return (
        <div
          className="items-conv shadow-default relative mt-0 mb-[22px] flex flex-row items-center rounded-[16px] border border-[#CFCAB8]"
          style={{
            animationDelay: "0ms",
          }}
        >
          <div className="relative w-full">
            <select
              className="item-input w-full grow resize-none appearance-none border-0 bg-transparent py-2.5 px-3 text-[14px] text-dark-blue outline-0 placeholder:text-[#CFCAB8] focus:outline-none"
              style={{ WebkitAppearance: "none", MozAppearance: "none" }}
              onChange={(e) => {
                const v = e.currentTarget.value;
                if (v !== "") {
                  updateAnswers(v);
                }
              }}
            >
              <option value="">
                {item.inputPlaceholder ? item.inputPlaceholder : "Ange värde"}
              </option>
              {item.options?.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </select>
            <div className="absolute right-[12px] top-[16px]">
              {getArrowIcon("#CFCAB8")}
            </div>
          </div>
        </div>
      );
    };

    const finishItem = () => {
      setFinished(true);
      submitValue();
    };

    return (
      <div className="flex flex-col gap-3" ref={ref}>
        {renderQuestion(item)}
        {renderOptions()}
        {renderInputOption()}
        {renderSelectOption()}
        {renderConfirmationForMultiple()}
      </div>
    );
  }
);

Item.displayName = "ConversationItem";
export default Item;
