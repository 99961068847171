import * as Sentry from "@sentry/react";

import {
  ContractSet,
  ContractSubmit,
  ContractSubmitResponse,
} from "../types/entities";
import { IdaContractDownloadBase64Type } from "../types/ida";
import { UserProfileType } from "../types/user";

export const apiUrl: string = process.env.IDA_API_URL;
const tenantId = 1;

export async function getContract(id: string): Promise<ContractSet> {
  const response = await fetch(`${apiUrl}/public/contract/template/${id}`, {
    method: "GET",
  }).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = (await response.json()) as ContractSet;
    return data ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
}

export async function downloadContract(
  accessToken: string,
  caseId: string,
  fileId: string
) {
  const response = await fetch(
    `${apiUrl}/public/customer/${caseId}/document/${fileId}/base64`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = (await response.json()) as {
      object: IdaContractDownloadBase64Type;
    };
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
}

export async function submitContract(
  accessToken: string,
  data: ContractSubmit
) {
  const response = await fetch(`${apiUrl}/public/customer/contract`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = (await response.json()) as {
      value: { object: IdaContractDownloadBase64Type };
    };
    return data.value.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
}

export const getProfileInfo = async (accessToken: string) => {
  const response = await fetch(
    `${apiUrl}/public/customer/profile?tenantId=${tenantId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = (await response.json()) as { object: UserProfileType };
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const updateProfileInfo = async (
  accessToken: string,
  data: UserProfileType
) => {
  const response = await fetch(
    `${apiUrl}/public/customer/profile?tenantId=${tenantId}`,
    {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = (await response.json()) as { object: UserProfileType };
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getUserData = async (accessToken: string): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/customer/datastore`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getUserDataByKey = async (
  accessToken: string,
  key: string
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/customer/datastore/${key}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? false;
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const setUserData = async (
  accessToken: string,
  data: { key: string; value: string }[]
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/customer/datastore`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const deleteUserDataByKey = async (
  accessToken: string,
  key: string
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/customer/datastore/${key}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    return true ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getPackageById = async (
  accessToken: string,
  id: number
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/package/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const setUserPackage = async (
  accessToken: string,
  id: number
): Promise<any> => {
  const data = { packageId: id };
  const response = await fetch(
    `${apiUrl}/public/customer/package?tenantId=${tenantId}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getUserPackage = async (accessToken: string): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/customer/package`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getPaymentClientSecret = async (
  accessToken: string,
  stripeSubscriptionId: string,
  packageId: string
): Promise<any> => {
  const params = new URLSearchParams({
    sid: stripeSubscriptionId,
    cpid: packageId,
  });

  const response = await fetch(
    `${apiUrl}/public/payment/stripe/clientsecret?${params.toString()}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const refreshAccessToken = async (
  accessToken: string,
  refreshToken: string
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/bankid/refreshtoken`, {
    method: "POST",
    body: JSON.stringify({
      AccessToken: accessToken,
      RefreshToken: refreshToken,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureException(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};
