import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import ContactAdvisor from "../components/ContactAdvisor";
import LoaderScreen from "../components/LoaderScreen";
import PageHead from "../components/PageHead";
import ProfileForm from "../components/ProfileForm";
import ProfileSection from "../components/ProfileSection";
import { RecommendationCard } from "../components/RecommendationCard";
import { useChatContext } from "../context/ChatContext";
import {
  useConvContext,
  useConvDispatchContext,
} from "../context/ConversationContext";
import { RequireUserAuth } from "../context/UserContext";
import { useUserContext } from "../context/UserContext";
import { contracts, getRecommendedContracts } from "../services/contracts";
import {
  getChatIcon,
  getFileIcon,
  getHeartIcon,
  getUserIcon,
} from "../services/icons";
import {
  deleteUserDataByKey,
  downloadContract,
  getProfileInfo,
  getUserData,
  getUserPackage,
} from "../services/ida";
import {
  conversationKey,
  removeFromStorage,
  setInStorage,
} from "../services/storage";
import { sendCmpEvent } from "../services/tracking";
import { encodeBase64, isDesktopView, isIOSSafari } from "../services/utils";
import { IdaContractDownloadBase64Type } from "../types/ida";
import { UserPackageType } from "../types/user";

const pencilIcon = new URL("../assets/images/pencil.svg", import.meta.url);
const pdfIcon = new URL("../assets/images/pdf.png", import.meta.url);

function ProfilePage() {
  const [loading, setLoading] = useState(false);
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [packageData, setPackageData] = useState<UserPackageType | null>();
  const [convData, setConvData] = useState();
  const navigate = useNavigate();
  const chat = useChatContext();

  const { user, fetchPackageData, fetchProfileData, fetchUserData } =
    useUserContext();
  const { conversation, fetchConversationData } = useConvContext();
  const dispatchConv = useConvDispatchContext();

  useEffect(() => {
    if (!user.info) {
      navigate("/login");
      return;
    }
    if (user.accessToken) {
      fetchPackageData(user.accessToken);
      fetchConversationData(user.accessToken);
      fetchUserData(user.accessToken);
    }

    if (chat?.current) {
      chat.current.classList.remove("chat-widget-hidden");
    }
  }, []);

  useEffect(() => {
    setPackageData(user.package);
  }, [user]);

  useEffect(() => {
    setConvData(conversation);
  }, [conversation]);

  useEffect(() => {
    if (!convData || !packageData?.contracts || !packageData.contracts) {
      return;
    }

    const recommendedContracts = getRecommendedContracts(
      convData.values,
      user.info?.age
    );
    const recommendedContractsIds = recommendedContracts.map((el) => el.id);

    const downloadableRecommendedContracts = packageData.contracts.reduce(
      (acc, el) => {
        const accVal = [...acc];
        if (
          el.documents &&
          el.documents.length > 0 &&
          recommendedContractsIds.includes(el.template)
        ) {
          accVal.push(el);
        }
        return accVal;
      },
      []
    );

    if (
      recommendedContractsIds.length > 0 &&
      recommendedContractsIds.length ===
        downloadableRecommendedContracts.length &&
      user.profile
    ) {
      void sendCmpEvent("Mail_10", user.profile.email);
    }
  }, [convData]);

  const showRecommendation = () => navigate("/recommendation");

  const canShowRecommendation = () => {
    if (
      convData &&
      convData.status &&
      (convData.status === "recommendation" ||
        convData.status === "finished" ||
        convData.status === "packet")
    ) {
      return true;
    }
    return false;
  };

  const payOrSelectPackage = () => {
    if (haveNoPackage()) {
      setLoading(true);
      dispatchConv({ type: "set-packet-status" });
      setTimeout(() => {
        navigate("/conversation");
      }, 300);
    } else {
      const dt = encodeBase64({ s: packageData.sid, p: packageData?.pid });
      navigate(`/payment/${dt}`);
    }
  };

  const downloadFile = (fileId: number) => {
    if (!user.accessToken || !packageData) {
      return;
    }
    setLoading(true);
    downloadContract(
      user.accessToken,
      packageData.caseId.toString(),
      fileId.toString()
    )
      .then((data) => {
        setLoading(false);
        const res = async (d: IdaContractDownloadBase64Type) => {
          const fileName = d.fileName;
          const base64Response = await fetch(d.base64Content);
          const blob = await base64Response.blob();
          const href = URL.createObjectURL(blob);

          if (!isDesktopView() && isIOSSafari()) {
            window.open(href, "_self");
            return;
          }

          const downloadLink = document.createElement("a");
          downloadLink.href = href;
          downloadLink.target = "_blank";
          if (isDesktopView()) {
            downloadLink.download = fileName;
          }
          downloadLink.rel = "noreferrer";
          downloadLink.click();
        };
        void res(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateContractLink = (contactData) => {
    const startData = encodeBase64({
      tplId: contactData.template,
      cId: contactData.id,
      caseId: packageData.caseId,
    });
    return `/contract/${startData}`;
  };

  const renderFeaturedContracts = () => {
    if (!packageData?.contracts || !user.info) {
      return null;
    }

    const contractsData = packageData.contracts;
    const recommendedContracts = canShowRecommendation()
      ? getRecommendedContracts(convData.values, user.info?.age)
      : [];

    const otherContracts = contracts.reduce((acc, cur) => {
      const accVal = [...acc];
      const isRecommended = recommendedContracts.find((e) => e.id === cur.id);
      if (!isRecommended) {
        accVal.push(cur);
      }
      return accVal;
    }, []);

    const downloadableContracts = contractsData.reduce((acc, el) => {
      const accVal = [...acc];
      if (el.documents.length > 0) {
        accVal.push(el);
      }
      return accVal;
    }, []);

    return (
      <>
        <div className="flex flex-col gap-6">
          {recommendedContracts.length > 0 && (
            <div className="flex flex-col gap-5">
              <div className="text-[13px]">Dina rekommendationer:</div>
              <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3">
                {recommendedContracts.map((c) => {
                  const contactDataRaw = packageData?.contracts.filter(
                    (elm) => c.id === elm.template
                  );
                  if (contactDataRaw.length === 0) {
                    return null;
                  }
                  const contactData = contactDataRaw[0];
                  return (
                    <div key={c.id} className="">
                      <Link
                        to={generateContractLink(contactData)}
                        className="block"
                      >
                        <RecommendationCard
                          title={c.title}
                          subtitle={c.subtitle}
                          theme={c.bgColor}
                          rightArrow={true}
                          profileView={true}
                          profileStatus={
                            contactData.documents.length > 0
                              ? "Uppdatera avtal"
                              : "Skapa avtal"
                          }
                        />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {otherContracts.length > 0 && (
            <div className="flex flex-col gap-5">
              <div className="text-[13px]">Våra övriga produkter:</div>
              <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3">
                {otherContracts.map((c) => {
                  const contactDataRaw = packageData?.contracts.filter(
                    (elm) => c.id === elm.template
                  );
                  if (contactDataRaw.length === 0) {
                    return null;
                  }
                  const contactData = contactDataRaw[0];

                  return (
                    <div key={c.id} className="">
                      <Link
                        to={generateContractLink(contactData)}
                        className="block"
                      >
                        <RecommendationCard
                          title={c.title}
                          subtitle={c.subtitle}
                          theme="white"
                          rightArrow={true}
                          profileView={true}
                          profileStatus={
                            contactData.documents.length > 0
                              ? "Uppdatera avtal"
                              : "Skapa avtal"
                          }
                        />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {downloadableContracts.length > 0 && (
          <>
            <hr className="my-4 border-b-[#CFCAB8] border-opacity-25 lg:my-8" />
            <div className="mb-4 text-[13px] lg:mb-6">
              Sparade dokument ({downloadableContracts.length})
            </div>
            <div className="lg:grid-none grid grid-cols-2 gap-4 lg:flex lg:flex-row lg:gap-8">
              {downloadableContracts.map((el) => {
                const contract = contracts.find((e) => e.id === el.template);
                if (!contract) return;

                let docToDownload = 0;
                if (el.documents.length > 0) {
                  docToDownload = el.documents[el.documents.length - 1].id;
                }
                return (
                  <div
                    key={el.template}
                    className="flex cursor-pointer flex-col items-center justify-center gap-3"
                    onClick={() => downloadFile(docToDownload)}
                  >
                    <img src={pdfIcon.href} width={56} />
                    <div className="font-intermedium text-[13px] text-dark-blue">
                      {contract.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </>
    );
  };

  const getProfileData = () => {
    if (!user.accessToken) {
      return;
    }
    void fetchProfileData(user.accessToken);
  };

  const openEditProfileForm = () => {
    if (!user.accessToken) {
      return;
    }
    setLoading(true);
    fetchProfileData(user.accessToken)
      .then(() => {
        setShowProfileForm(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const haveNoPackage = () => !packageData;
  const havePayedPackage = () => packageData && packageData.status === "Active";
  const haveIncompletePackage = () =>
    packageData &&
    (packageData.status === "Incomplete" ||
      packageData.status === "IncompleteExpired");

  if (loading || !user.info) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }
  return (
    <div className="grow">
      <PageHead
        title={`${user.info.firstName} ${user.info.lastName}`}
        subtitle="kassaskåp"
        icon="vault"
        rightComp={
          havePayedPackage() && (
            <div className="absolute right-0 bottom-[15px]">
              <ContactAdvisor iconPosition="left" />
            </div>
          )
        }
      />

      {!showProfileForm && (
        <div className="mt-2 flex flex-row justify-between lg:pl-[40px]">
          <div className="flex-1">
            <div className="mb-2 font-intermedium text-[13px] uppercase">
              {user.info.personNumber}
            </div>
            {user.profile && (
              <div className="font-intermedium text-[11px] leading-[15px] text-[#CECABA]">
                <div>{user.profile.address.address}</div>
                <div>{user.profile.address.postalCode}</div>
                <div>{user.profile.address.city}</div>
                <div>{user.profile.phoneNumber}</div>
                <div>{user.profile.email}</div>
              </div>
            )}
          </div>
          <div className="flex-1">
            <div
              className="flex cursor-pointer flex-row justify-end gap-2 text-[12px]"
              onClick={() => openEditProfileForm()}
            >
              <div className="self-end text-right">
                <span className="font-intermedium underline">Redigera</span> /{" "}
                <span className="font-intermedium underline">Lägg till</span> i
                din profil
              </div>
              <div>
                <img src={pencilIcon.href} width={14} />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-5 lg:mt-10 lg:pl-[40px]">
        {showProfileForm ? (
          <ProfileSection
            title="Din Profil"
            icon={getUserIcon("#002733", "30", "100%")}
            folded={false}
            handleOnClose={() => setShowProfileForm(false)}
          >
            <ProfileForm onSuccessHandler={getProfileData} />
          </ProfileSection>
        ) : (
          <>
            {(!haveNoPackage() ||
              canShowRecommendation() ||
              haveIncompletePackage()) && (
              <div className="mb-3 lg:mb-7">
                <ProfileSection
                  title="Dina dokument"
                  subtitle="Här hittar du dina rekommendationer, avtal och dokument"
                  icon={getFileIcon("#002733", "20", "100%")}
                  folded={false}
                >
                  {(haveIncompletePackage() || haveNoPackage()) && (
                    <>
                      <div
                        onClick={payOrSelectPackage}
                        className="cursor-pointer text-[14px] underline"
                      >
                        Betala ditt paket för att börja skapa dina digitala
                        avtal.
                      </div>
                    </>
                  )}
                  {havePayedPackage() && renderFeaturedContracts()}
                </ProfileSection>
              </div>
            )}

            {canShowRecommendation() ? (
              <>
                <div className="mb-3 lg:mb-7">
                  <ProfileSection
                    title="Din rekommendation"
                    subtitle="Här hittar du din rekommendation som du även kan spara som PDF"
                    icon={getHeartIcon("#002733", "24", "100%")}
                    folded={true}
                    handleOnClick={showRecommendation}
                  />
                </div>
                <div>
                  <ProfileSection
                    title="Din livssituation"
                    subtitle="Klicka här för att uppdatera dina svar i behovsanalysen"
                    icon={getChatIcon("#002733", "22", "100%")}
                    folded={true}
                    handleOnClick={() => {
                      setLoading(true);
                      dispatchConv({ type: "reset-to-finished-or-started" });
                      setTimeout(() => {
                        navigate("/conversation");
                      }, 300);
                    }}
                  />
                </div>
              </>
            ) : (
              <div>
                <ProfileSection
                  title="Din livssituation"
                  subtitle="Klicka här för att besvara resterande frågor i behovsanalysen"
                  icon={getChatIcon("#002733", "22", "100%")}
                  folded={true}
                  handleOnClick={() => {
                    navigate("/conversation");
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default function ProfilePageWrapper() {
  return (
    <RequireUserAuth>
      <ProfilePage />
    </RequireUserAuth>
  );
}
