import React, { useEffect } from "react";

import ConversationList from "../components/conversation/List";
import { useChatContext } from "../context/ChatContext";
import { RequireUserAuth } from "../context/UserContext";

function ConversationPage() {
  const chat = useChatContext();

  useEffect(() => {
    if (chat?.current) {
      chat.current.classList.add("chat-widget-hidden");
    }
  }, []);

  return (
    <div className="flex grow flex-col">
      <ConversationList />
    </div>
  );
}

export default function ConversationPageWrapper() {
  return (
    <RequireUserAuth>
      <ConversationPage />
    </RequireUserAuth>
  );
}
