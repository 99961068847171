import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import ErrorBoundary from "./components/ErrorBoundary";
import Layout from "./components/Layout";
import LayoutPartner from "./components/LayoutPartner";
import LayoutPublic from "./components/LayoutPublic";
import { useUserContext } from "./context/UserContext";
import ContractPage from "./pages/ContractPage";
import ConversationPage from "./pages/ConversationPage";
import DebugPage from "./pages/DebugPage";
import IndexPage from "./pages/IndexPage";
import LoginPage from "./pages/LoginPage";
import NoPage from "./pages/NoPage";
import PartnerPage from "./pages/PartnerPage";
import PaymentPage from "./pages/PaymentPage";
import ProfilePage from "./pages/ProfilePage";
import RecommendationPage from "./pages/RecommendationPage";

export default function App() {
  const { user, populateFromStorage, saveToStorage } = useUserContext();

  useEffect(() => {
    populateFromStorage();
  }, []);

  useEffect(() => {
    saveToStorage();
  }, [user]);

  return (
    <ErrorBoundary>
      <Routes>
        <Route element={<LayoutPublic />}>
          <Route path="/" element={<IndexPage />} />
        </Route>
        <Route element={<LayoutPartner />}>
          <Route path="/partner/:pt?" element={<PartnerPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/vault" element={<ProfilePage />} />
          <Route path="/debug" element={<DebugPage />} />
          <Route path="/conversation" element={<ConversationPage />} />
          <Route path="/recommendation" element={<RecommendationPage />} />
          <Route path="/payment/:data" element={<PaymentPage />} />
          <Route path="contract/:contractQuery" element={<ContractPage />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
}
