import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../components/Button";
import QuestionList from "../components/contract/QuestionList";
import LoaderScreen from "../components/LoaderScreen";
import Modal from "../components/Modal";
import PageHead from "../components/PageHead";
import PdfPreview from "../components/PdfPreview";
import { useChatContext } from "../context/ChatContext";
import { ContractProvider, useContract } from "../context/ContractContext";
import { useConvContext } from "../context/ConversationContext";
import { useUserContext } from "../context/UserContext";
import { RequireUserAuth } from "../context/UserContext";
import {
  Contract,
  contracts,
  getRecommendedContracts,
} from "../services/contracts";
import { getContract, submitContract } from "../services/ida";
import { sendCmpEvent } from "../services/tracking";
import { decodeBase64, isDesktopView } from "../services/utils";
import { ContractSet, ContractSubmit } from "../types/entities";

const pdfIcon = new URL("../assets/images/pdf.png", import.meta.url);
type PreviewFileType = {
  content: string;
  type: string;
  filename: string;
};

function ContractPage() {
  const [data, setData] = useState<ContractSet>();
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState<Contract>({} as Contract);
  const [contractId, setContractId] = useState();
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState<PreviewFileType>();
  const [contractSubmitData, setContractSubmitData] = useState();
  const { contractQuery } = useParams<{ contractQuery: string }>();
  const navigate = useNavigate();

  const { user } = useUserContext();
  const chat = useChatContext();
  const { conversation } = useConvContext();
  const { contractData } = useContract();

  useEffect(() => {
    if (!user.info) {
      navigate("/login");
      return;
    }
    if (chat?.current) {
      chat.current.classList.remove("chat-widget-hidden");
    }
  }, []);

  useEffect(() => {
    const cq = decodeBase64(contractQuery);
    setContractId(cq.tplId);
    setContractSubmitData(cq);
  }, []);

  useEffect(() => {
    const res = contracts.filter((el) => {
      return el.id === contractId;
    });
    if (res.length === 0) {
      return;
    }
    setContract(res[0]);
  }, [contractId]);

  useEffect(() => {
    if (!contract?.id) {
      return;
    }
    startQuiz();
  }, [contract]);

  const startQuiz = () => {
    setLoading(true);

    if (contract.title === "Testamente" && user.profile) {
      void sendCmpEvent("Mail_7", user.profile.email);
    }

    const recCont = getRecommendedContracts(
      conversation.values,
      user.info?.age
    );

    const isPrimary = recCont.find((cont) => cont.id === contract.id);
    if (!isPrimary && user.profile) {
      void sendCmpEvent("Mail_9", user.profile.email);
    }

    getContract(contract.id)
      .then((dt) => {
        setData(dt);
      })
      .catch((error: Error) => {
        Sentry.captureException(error);
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitAnswers = () => {
    const logData = {};
    for (const [key, value] of Object.entries(contractData.answersLog)) {
      const cleanValue = { ...value };
      delete cleanValue.prevState;
      logData[key] = cleanValue;
    }

    setLoading(true);

    const data: ContractSubmit = {
      templateId: contract.id,
      contact: {
        firstName: user.profile ? user.profile.firstName : "",
        lastName: user.profile ? user.profile.lastName : "",
        email: user.profile ? user.profile.email : "test@email.com",
        phone: user.profile ? user.profile.phoneNumber : "111111111",
      },
      answers: { main: logData },
      packageType: contract.type,
      customerPackageProductId: contractSubmitData.cId,
    };

    if (contract.title === "Testamente" && user.profile) {
      void sendCmpEvent("Mail_8", user.profile.email);
    }

    submitContract(user.accessToken, data)
      .then((resp) => {
        setPreviewModal(true);
        setPreviewFile({
          content: resp.base64Content,
          type: resp.contentType,
          filename: resp.fileName,
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.log("ERR", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPreviewClose = () => {
    setPreviewModal(!previewModal);
    setPreviewFile(undefined);
    navigate("/vault");
  };

  const renderPreviewModal = () => {
    if (!previewModal) {
      return;
    }
    const attrs = {
      href: previewFile.content,
      target: "_blank",
      rel: "noreferrer",
      className: "flex flex-row items-center gap-2",
    };

    if (isDesktopView()) {
      attrs["download"] = previewFile.filename;
    }
    return (
      <Modal
        onClose={onPreviewClose}
        footerComp={
          <div className="z-10 my-5 mx-4 flex flex-col items-center justify-between gap-3 lg:flex-row">
            <Button
              onClick={() => navigate("/vault")}
              title="TILL DITT KASSASKÅP"
            />
            <a {...attrs}>
              <img src={pdfIcon.href} className="mr-1 w-[18px] lg:w-[27px]" />
              <div>
                <span className="cursor-pointer border-b-[1px] border-white font-intermedium text-[14px] uppercase tracking-widest text-white lg:border-dark-blue lg:text-dark-blue">
                  spara som pdf
                </span>
              </div>
            </a>
          </div>
        }
      >
        {previewFile && renderFile()}
      </Modal>
    );
  };

  function renderFile() {
    return (
      <div className="flex justify-center">
        <PdfPreview file={previewFile.content} />
      </div>
    );
  }

  if (loading) {
    return <LoaderScreen />;
  }

  return (
    <>
      <PageHead title={contract.title} icon="file" />
      <div className="mt-5 lg:mt-10">
        {data !== undefined && (
          <QuestionList
            contract={contract}
            data={data}
            submitAnswers={submitAnswers}
          />
        )}
      </div>
      {renderPreviewModal()}
    </>
  );
}

export default function ContractPageWrapper() {
  return (
    <RequireUserAuth>
      <ContractProvider>
        <ContractPage />
      </ContractProvider>
    </RequireUserAuth>
  );
}
