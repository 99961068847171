import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useServiceSectionContext } from "../context/ServiceSectionContext";
import { useUserContext } from "../context/UserContext";
import {
  getBankIdIcon,
  getButtonArrow,
  getCloseIcon,
  getFenixLogo,
  getLogInIcon,
  getLogOutIcon,
  getVaultLogo,
} from "../services/icons";
import Button from "./Button";
import Modal from "./Modal";
import { services } from "./ServicesSection";

export default function Head({
  homepage,
  theme,
  topLinks,
}: {
  homepage?: boolean;
  topLinks?: boolean;
  theme: "dark";
}) {
  const [closeWarningModal, setCloseWarningModal] = useState(false);
  const [closeWarningData, setCloseWarningData] = useState(false);

  const { user, logout } = useUserContext();
  const loc = useLocation();
  const navigate = useNavigate();
  const { toggleMode } = useServiceSectionContext();

  const performNavigate = (to, check = true) => {
    if (check && loc.pathname.includes("/contract/")) {
      setCloseWarningModal(true);
      setCloseWarningData(to);
      return;
    }
    setCloseWarningModal(false);
    setCloseWarningData(false);

    if (to === "logout") {
      logout();
    } else {
      navigate(to);
    }
  };

  let iconsColor = undefined;
  let textColor = "text-[#FFFEFB]";
  if (theme === "dark") {
    textColor = "text-dark-blue";
    iconsColor = "#002733";
  }

  const renderLoginButton = () => {
    if (homepage) {
      return (
        <Link
          to="/login"
          className="flex flex-row items-center gap-2 self-center rounded-[5px] border-[1px] border-[#ffffff] bg-[#0C2632] bg-opacity-10 p-2 lg:gap-3 lg:p-4"
        >
          {getBankIdIcon("#ffffff", "20", "100%")}
          <span
            className={`font-intermedium text-[14px] uppercase leading-none tracking-widest ${textColor}`}
          >
            logga in
          </span>
          <div className="hidden lg:block">
            {getButtonArrow("#ffffff", "18", "100%")}
          </div>
        </Link>
      );
    }
    return (
      <Link
        to="/login"
        className="flex flex-row items-center gap-2 self-center border-[1px] border-transparent p-2 lg:gap-3 lg:p-4"
      >
        {getLogInIcon(iconsColor)}
        <span
          className={`font-intermedium text-[14px] uppercase leading-none tracking-widest ${textColor}`}
        >
          logga in
        </span>
      </Link>
    );
  };

  return (
    <div className="my-4 flex w-full flex-row items-center justify-between lg:my-8 lg:flex-row">
      <div className="flex flex-row items-end gap-5">
        <a
          onClick={() => performNavigate("/")}
          className="w-[150px] cursor-pointer lg:w-[200px]"
        >
          {getFenixLogo(iconsColor, "100%", "100%")}
        </a>
        {topLinks && (
          <div
            className={`hidden self-end uppercase leading-none tracking-[0.1em] ${textColor} lg:flex lg:flex-grow lg:gap-2`}
          >
            {services
              ? services.map((service, ind) => (
                  <div className="flex flex-grow gap-2" key={service.id}>
                    <span
                      className={`${
                        homepage ? "cursor-pointer" : "cursor-default"
                      }`}
                      onClick={() => {
                        if (homepage) {
                          toggleMode(service.id, false);
                        }
                      }}
                    >
                      {service.title}
                    </span>
                    {ind + 1 !== services.length && "|"}
                  </div>
                ))
              : null}
          </div>
        )}
      </div>
      {!user?.info ? (
        renderLoginButton()
      ) : (
        <div
          className={`flex flex-row items-center gap-0 self-center text-[14px] ${textColor} lg:gap-3`}
        >
          {user.package && (
            <a
              onClick={() => performNavigate("/vault")}
              className="flex cursor-pointer flex-row items-center gap-2 font-intermedium uppercase leading-none tracking-widest lg:gap-3"
            >
              {getVaultLogo(iconsColor, "20", "100%")}
              <span className="hidden lg:inline">DITT KASSASKÅP</span>
            </a>
          )}

          <div className="flex flex-row items-center gap-2 border-[1px] border-transparent p-2 pr-0 leading-none lg:gap-3 lg:p-4">
            {getLogOutIcon(iconsColor)}
            <span
              className="cursor-pointer font-intermedium uppercase tracking-widest"
              onClick={() => performNavigate("logout")}
            >
              logga ut
            </span>
          </div>
        </div>
      )}

      {closeWarningModal && (
        <Modal title="" onClose={() => setCloseWarningModal(false)}>
          <div className="bg-[#FFFEFB] p-5 lg:bg-none lg:p-0">
            <div className="mt-3 w-full max-w-[590px]">
              Väljer du att avbryta innan alla frågor är besvarade försvinner
              svaren du angivit. Du är naturligtvis välkommen att börja om med
              ditt avtal närsomhelst.
            </div>
            <div className="mt-7 inline-block">
              <Button
                title="Jag vill avbryta"
                onClick={() => performNavigate(closeWarningData, false)}
                iconComp={getCloseIcon("#ffffff", "16", "100%", "2")}
                icon={{ width: -20 }}
                theme="red"
                rightArrow={false}
              />
              <button
                type="button"
                onClick={() => setCloseWarningModal(false)}
                className="mx-auto my-2 flex items-center gap-2 text-dark-blue"
              >
                <div>
                  <span className="border-b-[1px] border-dark-blue font-intermedium uppercase tracking-widest">
                    Jag vill fortsätta
                  </span>
                </div>
                {getButtonArrow("#0C2632", "18", "100%")}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
